import req from '../../utils/http'
//树形列表
export const classifyList = params => req("get", "/goods/v1/admin/classify/list", params)

//通用名列表
export const commonNameList = params => req("get", "/goods/v1/admin/common-name/list", params)
//通用名启用or禁用
export const commonNameUpdate = params => req("post", "/goods/v1/admin/common-name/update", params)
//新增or编辑 剂型list
export const drugDosage = params => req("get", "/dic/v1/admin/drug-dosage", params)

//通用名添加
export const commonNameStore = params => req("post", "/goods/v1/admin/common-name/store", params)
//通用名编辑
export const commonNameEdit = params => req("post", "/goods/v1/admin/common-name/edit", params)
//通用名详情
export const commonNameDetail = params => req("get", "/goods/v1/admin/common-name/detail", params)


//商品名列表
export const informationList = params => req("get", "/goods/v1/admin/information/list", params)
//商品名启用or禁用
export const informationUpdate = params => req("post", "/goods/v1/admin/information/update", params)
//商品名新增
export const informationStore = params => req("post", "/goods/v1/admin/information/store", params)
//商品名详情
export const informationDetail = params => req("get", "/goods/v1/admin/information/detail", params)

//通用名树形结构
export const classifyData = params => req("get", "/goods/v1/admin/classify/data", params)
//商品编辑
export const adminInformationEdit = params => req("post", "/goods/v1/admin/information/edit", params)
<template>
  <div class="NewTradeName">
    <el-drawer
      :title="tradeName.rowData == null ? '新建商品名' : '编辑商品名'"
      :size="754"
      :wrapperClosable="false"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="cancelClick"
    >
      <div class="NewTradeName_box">
        <el-form
          :inline="true"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          size="small"
        >
          <el-form-item label="商品名" prop="name" style="margin-right: 50px">
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.name"
              style="width: 208px"
              @input="pinyinChange"
            ></el-input>
          </el-form-item>
          <el-form-item label="拼音码" prop="pinyin">
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.pinyin"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="classify_id">
            <el-cascader
              ref="cascader"
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.classify_id"
              placeholder="请选择"
              :options="options"
              filterable
              style="width: 208px"
              :props="{ value: 'id', label: 'title', checkStrictly: false }"
              @change="cascaderChange"
            ></el-cascader>

            <span style="margin-left: 20px"
              >剂型 - {{ ruleForm.dosage_form }}</span
            >
          </el-form-item>
          <el-form-item label="单位" prop="unit" style="margin-right: 50px">
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.unit"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="规格" prop="norm">
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.norm"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="产地"
            prop="production_address"
            style="margin-right: 50px"
          >
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.production_address"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="保质期" prop="expiration_date">
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.expiration_date"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="厂家" prop="factory" style="margin-right: 50px">
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.factory"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="image_url">
            <div>
              <div class="uploadbox" @click="uploadImgClick">
                <img :src="imageSrc" alt="" v-if="imageSrc" />
                <i class="el-icon-plus"></i>
              </div>
              <input
                v-if="imageSrc == ''"
                name="file"
                v-show="false"
                ref="fileRef"
                class="fileRef"
                type="file"
                accept="image/png,image/gif,image/jpeg"
                @change="fileChange"
              />
            </div>
          </el-form-item>
          <el-form-item
            label="批准文号"
            prop="approval_number"
            style="margin-right: 50px"
          >
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.approval_number"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="单价" prop="price">
            <el-input
              :disabled="tradeName.rowData !== null"
              v-model="ruleForm.price"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="小包装单位" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.small_packaging_unit"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="大包装单位">
            <el-input
              v-model="ruleForm.large_packaging_unit"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="特殊单位" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.special_unit"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <label slot="label">
              <div class="formLabel">
                默认单次剂量
                <span>（单位）</span>
              </div>
            </label>
            <el-input
              v-model="ruleForm.default_single_dose"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="基本药物分类" style="margin-right: 50px">
            <el-input v-model="ruleForm.emc" style="width: 208px"></el-input>
          </el-form-item>
          <el-form-item label="禁忌">
            <el-input v-model="ruleForm.taboo" style="width: 208px"></el-input>
          </el-form-item>
          <el-form-item label="不良反应" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.adverse_reactions"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleForm.remark"
              maxlength="500"
              show-word-limit
              size="small"
              style="width: 568px; height: 90px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="drawerFooter">
          <el-button size="small" @click="cancelClick">取消</el-button>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import pinyin from "js-pinyin";
import axios from "axios";
import {
  commonNameList,
  informationStore,
  informationDetail,
  classifyData,
  adminInformationEdit,
} from "../../../api/drugs/drugs";

var ruleFormData = {
  name: "",
  pinyin: "",
  classify_id: "",
  dosage_form: "",
  unit: "",
  norm: "",
  production_address: "",
  expiration_date: "",
  factory: "",
  image_url: "",
  approval_number: "",
  small_packaging_unit: "",
  large_packaging_unit: "",
  special_unit: "",
  default_single_dose: "",
  emc: "",
  taboo: "",
  adverse_reactions: "",
  remark: "",
  price: "",
};
export default {
  data() {
    return {
      imageSrc: "",
      drawer: false,
      ruleForm: JSON.parse(JSON.stringify(ruleFormData)),
      rules: {
        name: [{ required: true, message: "请输入商品名", trigger: "blur" }],
        pinyin: [
          { required: true, message: "请输入拼音码", trigger: "change" },
        ],
        classify_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        norm: [{ required: true, message: "请输入规格", trigger: "blur" }],
        production_address: [
          { required: true, message: "请输入产地", trigger: "blur" },
        ],
        expiration_date: [
          { required: true, message: "请选择保质期", trigger: "blur" },
        ],
        factory: [{ required: true, message: "请输入厂家", trigger: "blur" }],
        image_url: [
          { required: true, message: "请上传图片", trigger: "blur" },
          { required: true, message: "请上传图片", trigger: "change" },
        ],
        approval_number: [
          { required: true, message: "请输入批准文号", trigger: "blur" },
        ],
        price: [
          {
            required: true,
            message: "请输入单价",
            trigger: "blur",
          },
        ],
      },
      options: [],
      optionsOne: [],
      optionsTwo: [],
      optionsThree: [],
    };
  },
  props: {
    tradeName: {
      type: Object,
    },
  },
  watch: {
    tradeName: {
      handler(val) {
        console.log(val);
        console.log(this.$store.state.commonNameData);

        this.drawer = val.drawerIs;

        console.log(this.ruleForm.classify_id);
        if (this.tradeName.rowData != null) {
          this.informationDetailAxios();
        } 
        else {
          this.$nextTick(() => {
            console.log(this.$store.state.commonNameData)
            let array =this.findPathByLeafId('code',this.$store.state.commonNameData.code,this.options)
            let arrayT =[]
            array.forEach(element => {
              arrayT.push(element.id)
            });
            this.ruleForm.classify_id = arrayT;
            this.ruleForm.dosage_form =
              this.$store.state.commonNameData.dosage_form;
            // this.ruleForm.classify_id =
            console.log("打印：",arrayT );
          });
        }
      },
      deep: true,
    },
    drawer(val) {
      this.ruleForm = JSON.parse(JSON.stringify(ruleFormData));
      this.$emit("drawerChange", val);
    },
  },
  created() {
    // this.commonNameListAxios();
    console.log(this.$store.state.commonNameData);

    this.classifyDataAxios();
  },
  methods: {
    findPathByLeafId(keys, leafId, nodes, path) {
      if (path === undefined) {
        path = [];
      }
      for (var i = 0; i < nodes.length; i++) {
        var tmpPath = path.concat();
        tmpPath.push({
          ...nodes[i],
          children: [],
        });
        if (leafId == nodes[i][keys]) {
          return tmpPath;
        }
        if (nodes[i].children) {
          var findResult = this.findPathByLeafId(
            keys,
            leafId,
            nodes[i].children,
            tmpPath
          );
          if (findResult) {
            return findResult;
          }
        }
      }
    },
    informationEditAxios() {
      console.log(this.tradeName);
      adminInformationEdit({
        id: this.tradeName.rowData.id,
        production_address: this.ruleForm.production_address,
        expiration_date: this.ruleForm.expiration_date,
        // image_url: this.ruleForm.image_url,
        small_packaging_unit: this.ruleForm.small_packaging_unit,
        large_packaging_unit: this.ruleForm.large_packaging_unit,
        special_unit: this.ruleForm.special_unit,
        default_single_dose: this.ruleForm.default_single_dose,
        emc: this.ruleForm.emc,
        taboo: this.ruleForm.taboo,
        remark: this.ruleForm.remark,
        adverse_reactions: this.ruleForm.adverse_reactions,
        price: this.ruleForm.price,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.drawer = false;
        }
      });
    },
    cascaderChange() {
      console.log(this.ruleForm.classify_id);
      this.$nextTick(() => {
        this.ruleForm.dosage_form =
          this.$refs.cascader.getCheckedNodes()[0].data.dosage_form;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tradeName.rowData == null) {
            this.informationStoreAxios();
          } else {
            console.log(this.ruleForm.image_url);
            this.$confirm(
              "保存后，该商品名相关信息将会同步更新，是否确认保存该编辑内容？",
              "保存",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                // iconClass: "el-icon-question",
              }
            )
              .then(() => {
                this.informationEditAxios();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
            // type: "warning",
            //             iconClass: "el-icon-question",
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    informationStoreAxios() {
      //新增
      informationStore({
        common_id:
          this.ruleForm.classify_id[this.ruleForm.classify_id.length - 1] ||
          this.ruleForm.classify_id,
        name: this.ruleForm.name,
        pinyin: this.ruleForm.pinyin,
        unit: this.ruleForm.unit,
        norm: this.ruleForm.norm,

        production_address: this.ruleForm.production_address,
        expiration_date: this.ruleForm.expiration_date,
        factory: this.ruleForm.factory,
        image_url: this.ruleForm.image_url,
        approval_number: this.ruleForm.approval_number,
        price: this.ruleForm.price,
        small_packaging_unit: this.ruleForm.small_packaging_unit,
        large_packaging_unit: this.ruleForm.large_packaging_unit,
        special_unit: this.ruleForm.special_unit,
        default_single_dose: this.ruleForm.default_single_dose,
        emc: this.ruleForm.emc,
        taboo: this.ruleForm.taboo,
        adverse_reactions: this.ruleForm.adverse_reactions,
        remark: this.ruleForm.remark,
        hosp_drug_code: "医院药品ID",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.drawer = false;
        }
      });
    },
    classifyDataAxios() {
      //分类
      classifyData().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    informationDetailAxios() {
      //编辑详情
      informationDetail({
        id: this.tradeName.rowData.id,
      }).then((res) => {
        console.log(res.data.image_url);
        console.log([...res.data.classify_ids, res.data.common_name_id]);
        this.ruleForm.name = res.data.name;
        this.ruleForm.pinyin = res.data.pinyin;
        this.ruleForm.classify_id = [
          ...res.data.classify_ids.map(Number),
          res.data.common_name_id,
        ];
        this.ruleForm.unit = res.data.unit;
        this.ruleForm.norm = res.data.norm;
        this.ruleForm.production_address = res.data.production_address;
        this.ruleForm.expiration_date = res.data.expiration_date;
        this.ruleForm.factory = res.data.factory;
        this.ruleForm.image_url = res.data.image_url;
        this.imageSrc = res.data.image_url;

        this.ruleForm.approval_number = res.data.approval_number;
        this.ruleForm.price = res.data.price;
        this.ruleForm.small_packaging_unit = res.data.small_packaging_unit;
        this.ruleForm.large_packaging_unit = res.data.large_packaging_unit;
        this.ruleForm.special_unit = res.data.special_unit;
        this.ruleForm.default_single_dose = res.data.default_single_dose;
        this.ruleForm.emc = res.data.emc;
        this.ruleForm.taboo = res.data.taboo;
        this.ruleForm.adverse_reactions = res.data.adverse_reactions;
        this.ruleForm.remark = res.data.remark;
        this.ruleForm.hosp_drug_code = res.data.hosp_drug_code;
        this.$nextTick(() => {
          this.ruleForm.dosage_form =
            this.$refs.cascader.getCheckedNodes()[0].data.dosage_form;
        });
      });
    },
    commonNameListAxios() {
      commonNameList().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.options = res.data.list;
        }
      });
    },
    cancelClick() {
      this.drawer = false;
    },
    pinyinChange() {
      this.ruleForm.pinyin = pinyin.getCamelChars(this.ruleForm.name);
    },
    uploadImgClick() {
      this.$nextTick(() => {
        if (this.tradeName.rowData == null) {
          let dom = document.querySelector(".fileRef");
          dom.dispatchEvent(new MouseEvent("click"));
        }
      });
    },
    fileChange(event) {
      // 上传文件
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      document.querySelector(".fileRef").value = "";
      if (formData.get("image")) {
        axios
          .post(
            this.$getAction.api + "/common/v1/admin/file/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                ticket: this.$cookies.get("jwtToken"),
              },
            }
          )
          .then((res) => {
            // this.qualification
            if (res.data.code == 200) {
              console.log(res.data);
              this.ruleForm.image_url = res.data.data.id;
              this.imageSrc = res.data.data.url;
            }
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.NewTradeName_box {
  height: 100%;
  padding: 24px 16px 50px 16px;
  box-sizing: border-box;
  position: relative;
  .drawerFooter {
    width: 100%;
    text-align: center;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #fff;
    padding: 20px 0;
    box-sizing: border-box;
  }
}
.uploadbox {
  display: inline-block;
  width: 62px;
  height: 62px;
  background-color: #ebeef8;
  border: 1px solid #c9cada;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  .el-icon-plus {
    font-size: 20px;
    color: rgb(221, 136, 50);
    font-size: 20px;
    font-weight: 800;
    line-height: 60px;
    position: relative;
    z-index: 99;
  }
  img {
    position: absolute;
    width: 62px;
    height: 62px;
    left: 0;
    top: o;
  }
}
.formLabel {
  position: relative;
  span {
    position: absolute;
    right: 0;
    bottom: -18px;
    font-size: 12px;
  }
}
</style>
<style lang="css">
.NewTradeName .el-textarea__inner {
  height: 100%;
}
</style>
<template>
  <div class="audit">
    <div v-if="$route.path == '/audit'">
      <div class="header">
        <div>
          <span class="span">医生ID</span>
          <el-input
            v-model="doctorId"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 18px"
          ></el-input>
          <span class="span">医生名称</span>
          <el-input
            v-model="doctorName"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 18px"
          ></el-input>
          <span class="span">医院名称</span>
          <el-input
            v-model="clinicName"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 18px"
          ></el-input>
          <span class="span">科室名称</span>
          <el-input
            v-model="deptName"
            placeholder="请输入"
            size="small"
            style="width: 200px"
          ></el-input>
        </div>
        <div style="margin-top: 16px">
          <span class="span">认证状态</span>
          <el-select
            v-model="statusVal"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 18px"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="未审核" value="1"> </el-option>
            <el-option label="未通过" value="2"> </el-option>
            <el-option label="已通过" value="3"> </el-option>
          </el-select>

          <span class="span">职称名称</span>
          <el-select
            v-model="appointVal"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 18px"
          >
            <el-option
              v-for="item in appointData"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <div class="btn">
            <el-button type="primary" size="small" @click="searchClick"
              >查询</el-button
            >
            <el-button size="small" @click="resetClick">重置</el-button>
          </div>
        </div>
      </div>
      <div>
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          height="calc(100vh - 296px)"
        >
          <el-table-column prop="id" label="医生ID"> </el-table-column>
          <el-table-column prop="name" label="医生名称"> </el-table-column>
          <el-table-column label="职称名称">
            <template slot-scope="scope">
              {{ scope.row.appoint.name }}
            </template>
          </el-table-column>

          <el-table-column prop="address" label="医院名称">
            <template slot-scope="scope">
              {{ scope.row.clinic.name }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="科室名称">
            <template slot-scope="scope">
              {{ scope.row.dept.name }}
            </template>
          </el-table-column>
          <el-table-column label="认证状态" prop="status_text">
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" width="150">
          </el-table-column>
          <el-table-column prop="updated_at" label="修改时间"  width="150">
          </el-table-column>
          <el-table-column label="审核备注">
            <template slot-scope="scope" v-if="scope.row.certify != null">
              {{ scope.row.certify.remark }}
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="detailClick(scope.row)"
                >认证详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div
          style="padding-top: 8px; box-sizing: border-box; text-align: right"
        >
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pageSize"
            layout=" prev, pager, next, jumper, sizes,total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 认证详情 -->
    <router-view></router-view>
  </div>
</template>
<script>
import { appoint, reviewDoctorlist } from "../../api/doctor/audit";
export default {
  data() {
    return {
      input: "",
      value: "",

      doctorId: "",
      doctorName: "",
      clinicName: "",
      deptName: "",

      statusVal: "",
      appointData: [],
      appointVal: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        if (this.$route.path == "/audit") {
          this.appointAxios();
          this.reviewDoctorlistAxios();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    if (this.$route.path == "/audit") {
      this.appointAxios();
      this.reviewDoctorlistAxios();
    }
  },

  methods: {
    searchClick() {
      this.reviewDoctorlistAxios();
    },
    resetClick() {
      this.doctorId = "";
      this.doctorName = "";
      this.clinicName = "";
      this.deptName = "";
      this.statusVal = "";
      this.appointVal = "";
      this.pageSize = 10;
      this.currentPage = 1;
      this.reviewDoctorlistAxios();
    },
    reviewDoctorlistAxios() {
      reviewDoctorlist({
        doctor_id: this.doctorId,
        name: this.doctorName,
        clinic_name: this.clinicName,
        dept_name: this.deptName,
        status: this.statusVal,
        appoint_code: this.appointVal,
        per_page: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    appointAxios() {
      this.appointData = [];
      appoint().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.appointData = res.data;
          this.appointData.unshift({
            code: "",
            name: "全部",
          });
        }
      });
    },
    detailClick(row) {
      console.log(row);
      this.$router.push({
        path: "/audit/detail",
        query: row,
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.reviewDoctorlistAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.reviewDoctorlistAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  padding: 16px;
  box-sizing: border-box;
  .span {
    display: inline-block;
    width: 56px;
    font-size: 14px;
    margin-right: 8px;
  }
  .btn {
    display: inline-block;
    margin-left: 424px;
  }
}
</style>
<template>
  <div class="enroll">
    <div class="titleHead">
      <span></span>
      <h4>基本信息</h4>
    </div>
    <div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormL"
        :inline="true"
        size="small"
      >
        <div>
          <el-form-item label="姓名" prop="name" label-width="80px">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="type" label-width="90px">
            <el-select
              v-model="ruleForm.type"
              placeholder="请选择"
              style="width: 200px"
            >
              <el-option label="医生" value="1"></el-option>
              <el-option label="护士" value="2"></el-option>
              <el-option label="药师" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职称名称" prop="appointCode" label-width="142px">
            <el-select
              v-model="ruleForm.appointCode"
              placeholder="请选择"
              style="width: 200px"
            >
              <el-option
                v-for="item in appointData"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="民族" prop="nation" label-width="80px">
            <el-select
              v-model="ruleForm.nation"
              placeholder="请选择"
              style="width: 200px"
            >
              <el-option
                v-for="item in nationData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历" prop="education" label-width="90px">
            <el-select
              v-model="ruleForm.education"
              placeholder="请选择"
              style="width: 200px"
            >
              <el-option label="小学" value="1"></el-option>
              <el-option label="初中" value="2"></el-option>
              <el-option label="中专" value="3"></el-option>
              <el-option label="高中" value="4"></el-option>
              <el-option label="大专" value="5"></el-option>
              <el-option label="本科" value="6"></el-option>
              <el-option label="硕士" value="7"></el-option>
              <el-option label="博士" value="8"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="医师第一执业机构"
            prop="firstclinicid"
            label-width="142px"
          >
            <el-autocomplete
              class="inline-input"
              v-model="ruleForm.first_clinic_name"
              :fetch-suggestions="searchHospitalAxios"
              placeholder="请输入内容"
              :trigger-on-focus="false"
              @select="handleSelect"
              style="width: 200px"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.name }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="性别" prop="sex" label-width="80px">
            <el-select
              v-model="ruleForm.sex"
              placeholder="请选择"
              style="width: 200px"
            >
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
              <el-option label="未知" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="行政职务" prop="position" label-width="90px">
            <el-input
              v-model="ruleForm.position"
              placeholder="请输入"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="编制科室名称" prop="dept_id" label-width="142px">
            <el-select
              v-model="ruleForm.dept_id"
              placeholder="请选择"
              style="width: 200px"
            >
              <el-option
                v-for="item in deptIdData"
                :key="item.code"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="联系方式" prop="phone" label-width="80px">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入"
              style="width: 200px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="通信地址" prop="address" label-width="90px">
            <el-input
              v-model="ruleForm.address"
              placeholder="请输入"
              style="width: 552px"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="擅长专业" prop="speciality" label-width="80px">
            <el-input
              type="textarea"
              placeholder="请输入"
              v-model="ruleForm.speciality"
              rows="3"
              style="width: 856px;he"
            >
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="个人简介" prop="introduce" label-width="80px">
            <el-input
              type="textarea"
              placeholder="请输入"
              v-model="ruleForm.introduce"
              rows="3"
              style="width: 856px"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div style="width: 936px; text-align: right">
        <el-button type="primary" size="small" @click="submitForm('ruleFormL')"
          >提交基本信息</el-button
        >
      </div>
    </div>

    <div class="titleHead">
      <span></span>
      <h4>实名认证</h4>
    </div>
    <div>
      <div class="rxm">
        <img
          v-if="!smformData.front_img"
          src="../../assets/images/rxm.png"
          alt=""
        />
        <img v-else :src="smformData.front_img" alt="" />
        <div class="rxm_div" @click="uploadClick('rxm')">点击上传人像面</div>
      </div>
      <div class="ghm">
        <img
          v-if="!smformData.back_img"
          src="../../assets/images/ghm.png"
          alt=""
        />
        <img v-else :src="smformData.back_img" alt="" />
        <div class="ghm_div" @click="uploadClick('ghm')">点击上传国徽面</div>
      </div>
      <input
        name="file"
        v-show="false"
        ref="fileRefsm"
        class="fileRefsm"
        type="file"
        accept="image/png,image/gif,image/jpeg"
        @change="fileChangesm"
      />
      <div class="authentication">
        <el-form
          :model="smformData"
          :rules="rules"
          ref="ruleFormsm"
          label-width="80px"
          size="small"
        >
          <el-form-item label="身份证号" prop="id_card">
            <el-input
              v-model="smformData.id_card"
              style="width: 274px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机验证" prop="verification_phone">
            <el-input
              placeholder="请输入"
              v-model="smformData.verification_phone"
              :disabled="true"
            >
              <div slot="suffix" class="suffix_btn">
                <span class="divder"></span>
                <el-button
                  type="text"
                  @click="getCodeClick"
                  :disabled="getCodeStr != '获取验证码'"
                  >{{ getCodeStr }}</el-button
                >
              </div>
            </el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="sms_code">
            <el-input
              v-model="smformData.sms_code"
              style="width: 274px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="width: 100%; text-align: right">
          <el-button
            type="primary"
            size="small"
            @click="doctorRealnameauthAxios('ruleFormsm')"
            >提交实名认证</el-button
          >
        </div>
      </div>
    </div>

    <div class="titleHead">
      <span></span>
      <h4>资质认证</h4>
    </div>

    <div>
      <div class="PracticeCertificate">
        <div class="PracticeCertificate_left">医师资质证</div>
        <div class="PracticeCertificate_upload">
          <div
            class="upload_box"
            style="margin-right: 20px"
            v-if="certificate_imageSrc != ''"
          >
            <img :src="certificate_imageSrc" alt="" />
            <div class="upload_box_dele" @click="delClick">删除</div>
          </div>
          <div class="upload_box" @click="uoloadClick" v-else>
            <i class="el-icon-plus"></i>
            <input
              name="file"
              v-show="false"
              ref="fileRef"
              class="fileRef"
              type="file"
              accept="image/png,image/gif,image/jpeg"
              @change="fileChange"
            />
          </div>
        </div>
      </div>
      <div class="PracticeCertificate">
        <div class="PracticeCertificate_left">医师执业证</div>
        <div class="PracticeCertificate_upload">
          <div
            class="upload_box"
            style="margin-right: 20px"
            v-if="practice_imageSrc != ''"
          >
            <img :src="practice_imageSrc" alt="" />
            <div class="upload_box_dele" @click="delClick2">删除</div>
          </div>
          <div class="upload_box" @click="uoloadClick2" v-else>
            <i class="el-icon-plus"></i>
            <input
              name="file"
              v-show="false"
              ref="fileRef2"
              class="fileRef2"
              type="file"
              accept="image/png,image/gif,image/jpeg"
              @change="fileChange2"
            />
          </div>
        </div>
      </div>
      <div class="PracticeCertificate">
        <div class="PracticeCertificate_left">医师职称证</div>
        <div class="PracticeCertificate_upload">
          <div
            class="upload_box"
            style="margin-right: 20px"
            v-if="title_imageSrc != ''"
          >
            <img :src="title_imageSrc" alt="" />
            <div class="upload_box_dele" @click="delClick3">删除</div>
          </div>
          <div class="upload_box" @click="uoloadClick3" v-else>
            <i class="el-icon-plus"></i>
            <input
              name="file"
              v-show="false"
              ref="fileRef3"
              class="fileRef3"
              type="file"
              accept="image/png,image/gif,image/jpeg"
              @change="fileChange3"
            />
          </div>
        </div>
      </div>
      <div style="width: 928px; text-align: right; margin-top: 24px">
        <el-button type="primary" size="small" @click="zzsubClick"
          >提交资质认证</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  appoint,
  searchHospital,
  getAll,
  doctorDept,
  doctorCreate,
  doctorRealnameauth,
  smsSendCode,
  doctorCertifyauth,
} from "../../api/physician/enroll";
import axios from "axios";
let ruleFormData = {
  name: "",
  type: "",
  appointCode: "",
  nation: "",
  education: "",
  firstclinicid: "",
  sex: "",
  position: "",
  dept_id: "",
  phone: "",
  address: "",
  speciality: "",
  introduce: "",
};
export default {
  data() {
    return {
      ruleForm: ruleFormData,
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        appointCode: [{ required: true, message: "请选择", trigger: "change" }],
        nation: [{ required: true, message: "请选择", trigger: "change" }],
        education: [{ required: true, message: "请选择", trigger: "change" }],
        firstclinicid: [
          { required: true, message: "请检索", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择", trigger: "change" }],
        position: [{ required: true, message: "请输入", trigger: "blur" }],
        dept_id: [{ required: true, message: "请选择", trigger: "change" }],
        phone: [{ required: true, message: "请输入", trigger: "blur" }],
        address: [{ required: true, message: "请输入", trigger: "blur" }],
        speciality: [{ required: true, message: "请输入", trigger: "blur" }],
        introduce: [{ required: true, message: "请输入", trigger: "blur" }],

        //实名认证
        id_card: [{ required: true, message: "请输入", trigger: "blur" }],
        verification_phone: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        sms_code: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      appointData: [],
      nationData: [],
      deptIdData: [],
      smformData: {
        front_img: "",
        back_img: "",
      },
      smActive: null,
      phone: "",
      getCodeStr: "获取验证码",
      id: "",
      certificate_image: "",
      certificate_imageSrc: "",
      practice_image: "",
      practice_imageSrc: "",
      title_image: "",
      title_imageSrc: "",
    };
  },
  computed: {
    userData() {
      // console.log(val)
      this.ruleForm.phone = this.$store.state.userData.phone;
      this.smformData.verification_phone = this.$store.state.userData.phone;
      this.phone = this.$store.state.userData.phone;
      return this.$store.state.userData;
    },
  },
  watch: {
    userData: {
      handler(val) {
        console.log(val);
        this.ruleForm.phone = val.phone;
        this.smformData.verification_phone = val.phone;
        this.phone = val.phone;
        console.log(this.$store.state.userData.phone);
      },
      deep: true,
    },
  },
  created() {
    this.appointAxios();
    this.getAllAxios();
    this.doctorDeptAxios();
    console.log();
  },
  methods: {
    zzsubClick() {
      this.doctorCertifyauthAxios();
    },
    doctorCertifyauthAxios() {
      doctorCertifyauth({
        id: this.id,
        certificate_image: this.certificate_image,
        practice_image: this.practice_image,
        title_image: this.title_image,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    getCodeClick() {
      this.smsSendCodeAxios();
    },
    smsSendCodeAxios() {
      smsSendCode({
        phone: this.phone,
        type: "login",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getCodeStr = 60;
          var time = setInterval(() => {
            if (this.getCodeStr > 0) {
              this.getCodeStr--;
            } else {
              this.getCodeStr = "获取验证码";
              clearInterval(time);
            }
          }, 1000);
        }
      });
    },
    uploadClick(str) {
      this.$nextTick(() => {
        // this.isFile = true;
        // this.activeRow = row;
        this.smActive = str;
        let dom = document.querySelector(".fileRefsm");
        dom.dispatchEvent(new MouseEvent("click"));
      });
    },
    fileChangesm(event) {
      // 上传文件
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      // formData.append("type", "goods_image");

      document.querySelector(".fileRefsm").value = "";
      if (formData.get("image")) {
        axios
          .post(
            this.$getAction.api + "/common/v1/admin/file/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "JWT-TOKEN": this.$cookies.get("jwtToken"),
                // ticket: this.$cookies.get("ticket"),
              },
            }
          )
          .then((res) => {
            // this.qualification
            // console.log(this.activeRow);
            console.log(res.data);
            if (res.data.code == 200) {
              if (this.smActive == "rxm") {
                this.smformData.front_img = res.data.data.url;
                this.smformData.front_imgname = res.data.data.id;
                // this.$set(this.smformData.front_img,res.data.data.url)
              }
              if (this.smActive == "ghm") {
                this.smformData.back_img = res.data.data.url;
                this.smformData.back_imgname = res.data.data.id;
              }
              this.$forceUpdate();
            }
          });
      }
    },
    doctorRealnameauthAxios(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          doctorRealnameauth({
            id: this.id,
            // id: 31,
            id_card: this.smformData.id_card,
            verification_phone: this.smformData.verification_phone,
            sms_code: this.smformData.sms_code,
            front_img: this.smformData.front_imgname,
            back_img: this.smformData.back_imgname,
          }).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    doctorDeptAxios() {
      doctorDept().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.deptIdData = res.data;
        }
      });
    },
    getAllAxios() {
      getAll().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.nationData = res.data;
        }
      });
    },
    searchHospitalAxios(queryString, cb) {
      searchHospital({
        hos_name: this.ruleForm.first_clinic_name,
      }).then((res) => {
        console.log(res.data.data);
        if (res.code == 200) {
          cb(res.data.data);
        }
      });
    },
    handleSelect(val) {
      console.log(val);
      this.ruleForm.firstclinicid = val.id;
      this.ruleForm.first_clinic_name = val.name;
      //  first_clinic_id:this.ruleForm.firstclinicid,
      //   first_clinic_name:this.ruleForm.first_clinic_name,
    },
    appointAxios() {
      appoint().then((res) => {
        if (res.code == 200) {
          this.appointData = res.data;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.doctorCreateAxios();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    doctorCreateAxios() {
      doctorCreate({
        name: this.ruleForm.name,
        type: this.ruleForm.type,
        appoint_code: this.ruleForm.appointCode,
        nation: this.ruleForm.nation,
        education: this.ruleForm.education,
        first_clinic_id: this.ruleForm.firstclinicid,
        first_clinic_name: this.ruleForm.first_clinic_name,

        sex: this.ruleForm.sex,
        position: this.ruleForm.position,
        dept_id: this.ruleForm.dept_id,
        phone: this.ruleForm.phone,
        address: this.ruleForm.address,
        speciality: this.ruleForm.speciality,

        introduce: this.ruleForm.introduce,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.id = res.data.id;
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    uoloadClick() {
      this.$nextTick(() => {
        let dom = document.querySelector(".fileRef");
        dom.dispatchEvent(new MouseEvent("click"));
      });
    },
    fileChange(event) {
      // 上传文件
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      document.querySelector(".fileRef").value = "";
      // this.certificate_imageSrc = window.URL.createObjectURL(
      //   event.target.files[0]
      // );
      if (formData.get("image")) {
        axios
          .post(
            this.$getAction.api + "/common/v1/admin/file/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "JWT-TOKEN": this.$cookies.get("jwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.code == 200) {
              this.certificate_image = res.data.data.id;
              this.certificate_imageSrc = res.data.data.url;
              this.$forceUpdate();
            }
          });
      }
    },
    delClick() {
      this.certificate_imageSrc = "";
      this.certificate_image = "";
    },

    uoloadClick2() {
      this.$nextTick(() => {
        let dom = document.querySelector(".fileRef2");
        dom.dispatchEvent(new MouseEvent("click"));
      });
    },
    fileChange2(event) {
      // 上传文件
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      document.querySelector(".fileRef2").value = "";
      // this.certificate_imageSrc = window.URL.createObjectURL(
      //   event.target.files[0]
      // );
      if (formData.get("image")) {
        axios
          .post(
            this.$getAction.api + "/common/v1/admin/file/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "JWT-TOKEN": this.$cookies.get("jwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.code == 200) {
              this.practice_image = res.data.data.id;
              this.practice_imageSrc = res.data.data.url;
              this.$forceUpdate();
            }
          });
      }
    },
    delClick2() {
      this.practice_imageSrc = "";
      this.practice_image = "";
    },

    uoloadClick3() {
      this.$nextTick(() => {
        let dom = document.querySelector(".fileRef3");
        dom.dispatchEvent(new MouseEvent("click"));
      });
    },
    fileChange3(event) {
      // 上传文件
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      document.querySelector(".fileRef3").value = "";
      // this.certificate_imageSrc = window.URL.createObjectURL(
      //   event.target.files[0]
      // );
      if (formData.get("image")) {
        axios
          .post(
            this.$getAction.api + "/common/v1/admin/file/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "JWT-TOKEN": this.$cookies.get("jwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.code == 200) {
              this.title_image = res.data.data.id;
              this.title_imageSrc = res.data.data.url;
              this.$forceUpdate();
            }
          });
      }
    },
    delClick3() {
      this.title_imageSrc = "";
      this.title_image = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.enroll {
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
  height: calc(100vh - 144px);
  overflow: scroll;
}
.titleHead {
  padding: 18px 0;
  box-sizing: border-box;
  span {
    width: 7px;
    height: 20px;
    display: inline-block;
    background-color: #2a2f63;
    vertical-align: middle;
    margin-right: 8px;
  }
  h4 {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }
}
.rxm {
  display: inline-block;
  position: relative;
  width: 250px;
  height: 240px;
  img {
    width: 100%;
    height: 100%;
  }
  .rxm_div {
    position: absolute;
    width: 100%;
    height: 60px;
    line-height: 60px;
    bottom: 0;
    left: 0;
    background-color: rgb(229, 233, 246);
    color: #394359;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }
  .rxm_divUpLoad {
    background-color: rgb(228, 100, 99);
    color: #ffffff;
  }
}
.ghm {
  display: inline-block;
  position: relative;
  width: 250px;
  height: 240px;
  margin: 0 40px;
  img {
    width: 100%;
    height: 100%;
  }
  .ghm_div {
    position: absolute;
    width: 100%;
    height: 60px;
    line-height: 60px;
    bottom: 0;
    left: 0;
    background-color: rgb(229, 233, 246);
    color: #394359;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }
  .ghm_divUpLoad {
    background-color: rgb(228, 100, 99);
    color: #ffffff;
  }
}
.authentication {
  display: inline-block;
  vertical-align: top;
  .divder {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin: 0 8px;
    vertical-align: middle;
    position: relative;
    background-color: #e69a41;
  }
}
.PracticeCertificate {
  display: inline-block;
  width: 928px;
  height: 96px;
  border: 1px dashed #c9cada;
  border-radius: 4px;
  margin-bottom: 24px;
  .PracticeCertificate_left {
    display: inline-block;
    width: 114px;
    height: 100%;
    line-height: 96px;
    background-color: #ebeef8;
    color: #394359;
    font-size: 14px;
    text-align: center;
  }
  .PracticeCertificate_upload {
    display: inline-block;
    width: calc(100% - 114px);
    height: 96px;
    padding: 18px 24px;
    box-sizing: border-box;
    vertical-align: middle;
  }
}
.upload_box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px solid #c9cada;
  border-radius: 4px;
  vertical-align: middle;
  line-height: 60px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  .upload_box_dele {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    line-height: 20px;
    background-color: #de241b;
    color: #fff;
    border-radius: 0px 0px 4px 4px;
    opacity: 0.6;
    font-size: 12px;
  }
  .el-icon-plus {
    font-size: 20px;
    font-weight: 800;
    color: #e69a41;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
import req from '../../utils/http'
//职称列表
export const appoint = params => req("get", "/common/v1/appoint", params)
//第一执业机构
export const searchHospital = params => req("get", "/clinic/v1/admin/doctor/search_hospital", params)
//民族
export const getAll = params => req("get", "/dic/v1/nation/get_all", params)
//科室
export const doctorDept = params => req("get", "/clinic/v1/admin/doctor/dept", params)

//list
export const doctorGetlist = params => req("get", "/clinic/v1/admin/doctor/getlist", params)


//list查看详情
export const doctorInfo = params => req("get", "/clinic/v1/admin/doctor/info", params)

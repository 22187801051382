<template>
  <div class="NewCommonName">
    <el-drawer
      :title="commonNameData.rowData == null ? '新建通用名' : '编辑通用名'"
      :size="754"
      :wrapperClosable="false"
      :visible.sync="drawer"
      direction="rtl"
      :destroy-on-close="true"
      :before-close="cancelClick"
    >
      <div class="drawerBox">
        <el-form
          :inline="true"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="96px"
          class="demo-ruleForm"
          size="small"
        >
          <el-form-item label="通用名" prop="name" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.name"
              style="width: 208px"
              @input="pinyinChange"
              :disabled="commonNameData.rowData !== null"
            ></el-input>
          </el-form-item>
          <el-form-item label="拼音码" prop="pinyin">
            <el-input
              v-model="ruleForm.pinyin"
              style="width: 208px"
              :disabled="commonNameData.rowData !== null"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="分类"
            prop="classify_id"
            style="margin-right: 50px"
          >
            <el-cascader
              :disabled="commonNameData.rowData !== null"
              v-model="ruleForm.classify_id"
              placeholder="请选择"
              :options="options"
              filterable
              style="width: 208px"
              :props="{ value: 'id', label: 'title' }"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="剂型" prop="dosage_form">
            <el-select
              v-model="ruleForm.dosage_form"
              placeholder="请选择"
              style="width: 208px"
              :disabled="commonNameData.rowData !== null"
            >
              <el-option
                v-for="item in drugDosageData"
                :key="item.code"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="抗菌药等级" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.antibacterial_grade"
              style="width: 208px"
            ></el-input>
          </el-form-item>

          <el-form-item label="是否皮试">
            <el-input
              v-model="ruleForm.skin_test"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="皮试限制" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.skin_limit"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="原液皮试">
            <el-input
              v-model="ruleForm.aw_liquid_skin_test"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="抗肿瘤药物" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.antitumor_drug"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="高危等级">
            <el-input
              v-model="ruleForm.high_risk_level"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="特殊药品" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.special_medicine"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="费用科目">
            <el-input
              v-model="ruleForm.expense_account"
              style="width: 208px"
            ></el-input>
          </el-form-item>

          <el-form-item label=" ">
            <el-checkbox v-model="ruleForm.tpn_drugs">tpn药物</el-checkbox>
            <el-checkbox v-model="ruleForm.pdd_test">pdd试验</el-checkbox>
            <el-checkbox-group
              v-model="ruleForm.executing_double_signature"
              style="display: inline-block; margin-right: 40px"
              :disabled="ruleForm.executing_double_signatureInput !== ''"
            >
              <el-checkbox label="执行双签名">
                执行双签名
                <el-input
                  :disabled="ruleForm.executing_double_signature == false"
                  v-model="ruleForm.executing_double_signatureInput"
                  style="width: 160px"
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox v-model="ruleForm.drug_traceability_code"
              >药品追溯码</el-checkbox
            >
          </el-form-item>
          <el-form-item label="易混淆标识" style="width: 100%">
            <el-checkbox-group v-model="ruleForm.confusing_signs">
              <el-checkbox v-for="city in citiesso" :label="city" :key="city">
                {{ city }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <el-checkbox-group
              v-model="ruleForm.limited_usage"
              :disabled="ruleForm.limited_usageInput !== ''"
            >
              <el-checkbox label="city"
                >限定用法
                <el-input
                  v-model="ruleForm.limited_usageInput"
                  :disabled="ruleForm.limited_usage == false"
                  style="width: 208px; margin-left: 12px"
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <el-checkbox-group
              v-model="ruleForm.limited_frequency"
              :disabled="ruleForm.limited_frequencyInput !== ''"
              style="margin-left: 42px"
            >
              <el-checkbox label="city"
                >限定频次
                <el-input
                  v-model="ruleForm.limited_frequencyInput"
                  :disabled="ruleForm.limited_frequency == false"
                  style="width: 208px; margin-left: 12px"
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="默认用法" style="margin-right: 50px">
            <el-input
              v-model="ruleForm.default_usage"
              style="width: 208px"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认频次">
            <el-input
              v-model="ruleForm.default_frequency"
              style="width: 208px"
            ></el-input>
          </el-form-item>

          <el-form-item label="取整方式">
            <el-input
              v-model="ruleForm.rounding_method"
              style="width: 208px"
            ></el-input>
            <el-input
              v-model="ruleForm.rounding_methodT"
              style="width: 208px; margin-left: 12px"
            ></el-input>
          </el-form-item>

          <el-form-item label="护理要点">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleForm.nursing_points"
              maxlength="500"
              show-word-limit
              style="width: 550px; height: 94px"
            ></el-input>
          </el-form-item>
          <el-form-item label="患者宣教">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleForm.patient_education"
              maxlength="500"
              show-word-limit
              style="width: 550px; height: 94px"
            ></el-input>
          </el-form-item>
          <el-form-item label="重点药物交代">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleForm.key_drug_interpretation"
              maxlength="500"
              show-word-limit
              style="width: 550px; height: 94px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="drawerFooter">
          <el-button size="small" @click="cancelClick">取消</el-button>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import pinyin from "js-pinyin";
import { classifyList } from "../../../api/drugs/drugs";
import {
  drugDosage,
  commonNameStore,
  commonNameEdit,
  commonNameDetail,
} from "../../../api/drugs/drugs";
var formData = {
  name: "",
  pinyin: "",
  classify_id: [],
  dosage_form: "",
  antibacterial_grade: "",
  skin_test: "",
  skin_limit: "",
  aw_liquid_skin_test: "",
  antitumor_drug: "",
  high_risk_level: "",
  special_medicine: "",
  expense_account: "",
  tpn_drugs: "",
  pdd_test: "",
  executing_double_signature: false,
  executing_double_signatureInput: "",
  drug_traceability_code: "",
  confusing_signs: [],
  limited_usage: false,
  limited_usageInput: "",
  limited_frequency: false,
  limited_frequencyInput: "",
  default_usage: "",
  default_frequency: "",
  rounding_method: "",
  rounding_methodT: "",
  nursing_points: "",
  patient_education: "",
  key_drug_interpretation: "",
};
export default {
  data() {
    return {
      drawer: false,
      ruleForm: JSON.parse(JSON.stringify(formData)),
      rules: {
        name: [{ required: true, message: "请输入通用名", trigger: "blur" }],
        pinyin: [{ required: true, message: "请输入拼音码", trigger: "blur" }],
        dosage_form: [
          { required: true, message: "请输入剂型", trigger: "blur" },
        ],
        classify_id: [
          { required: true, message: "请选择分类", trigger: "blur" },
        ],
      },
      options: [],
      value: "",
      checkedCities: [],
      citiesso: ["听似", "看似", "多规", "多厂家"],
      parent_id: "0",
      ActiveDataCom: {},
      drugDosageData: [],
    };
  },
  props: {
    commonNameData: {
      type: Object,
    },
  },
  watch: {
    commonNameData: {
      handler(val) {
        console.log(val);
        this.ruleForm = JSON.parse(JSON.stringify(formData))
        this.drawer = val.drawerIs;
        if (this.commonNameData.rowData !== null) {
          this.commonNameDetailAxios();
        }
        else {
          this.$nextTick(() => {
            let array =this.findPathByLeafId('id',this.$route.query.id,this.options)
            let arrayT =[]
            array.forEach(element => {
              arrayT.push(element.id)
            });
            this.ruleForm.classify_id = arrayT;
            // this.ruleForm.dosage_form =
            //   this.$store.state.commonNameData.dosage_form;
            // this.ruleForm.classify_id =
            console.log("打印：",arrayT );
          });
        }
      },
      deep: true,
    },
    drawer(val) {
      this.$emit("drawerChange", val);
    },
  },
  created() {
    this.drugDosageAxios();
    this.classifyListAxios();
  },
  methods: {
    findPathByLeafId(keys, leafId, nodes, path) {
      if (path === undefined) {
        path = [];
      }
      for (var i = 0; i < nodes.length; i++) {
        var tmpPath = path.concat();
        tmpPath.push({
          ...nodes[i],
          children: [],
        });
        if (leafId == nodes[i][keys]) {
          return tmpPath;
        }
        if (nodes[i].children) {
          var findResult = this.findPathByLeafId(
            keys,
            leafId,
            nodes[i].children,
            tmpPath
          );
          if (findResult) {
            return findResult;
          }
        }
      }
    },
    classifyListAxios() {
      classifyList({
        type: "tree",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    commonNameStoreAxios() {
      console.log(this.ruleForm.classify_id);
      //添加
      commonNameStore({
        name: this.ruleForm.name,
        pinyin: this.ruleForm.pinyin,
        classify_id:
          this.ruleForm.classify_id[this.ruleForm.classify_id.length - 1],
        dosage_form_id: this.ruleForm.dosage_form,
        common_name_attr_val: JSON.stringify([
          {
            input_name: "antibacterial_grade",
            input_type: "text",
            attr: "抗菌药等级",
            val: this.ruleForm.antibacterial_grade,
          },
          {
            input_name: "skin_test",
            input_type: "text",
            attr: "是否皮试",
            val: this.ruleForm.skin_test,
          },
          {
            input_name: "skin_limit",
            input_type: "text",
            attr: "皮试限制",
            val: this.ruleForm.skin_limit,
          },
          {
            input_name: "aw_liquid_skin_test",
            input_type: "text",
            attr: "原液皮试",
            val: this.ruleForm.aw_liquid_skin_test,
          },
          {
            input_name: "antitumor_drug",
            input_type: "text",
            attr: "抗肿瘤药物",
            val: this.ruleForm.antitumor_drug,
          },
          {
            input_name: "high_risk_level",
            input_type: "text",
            attr: "高危等级",
            val: this.ruleForm.high_risk_level,
          },
          {
            input_name: "special_medicine",
            input_type: "text",
            attr: "特殊药品",
            val: this.ruleForm.special_medicine,
          },
          {
            input_name: "expense_account",
            input_type: "text",
            attr: "费用科目",
            val: this.ruleForm.expense_account,
          },
          {
            input_name: "tpn_drugs",
            input_type: "checkbox",
            attr: "tpn药物",
            val: this.ruleForm.tpn_drugs,
          },
          {
            input_name: "pdd_test",
            input_type: "checkbox",
            attr: "pdd试验",
            val: this.ruleForm.pdd_test,
          },
          {
            input_name: "executing_double_signature",
            input_type: "checkbox_text",
            attr: "执行双签名",
            val: this.ruleForm.executing_double_signatureInput,
          },
          {
            input_name: "drug_traceability_code",
            input_type: "checkbox",
            attr: "药品追溯码",
            val: this.ruleForm.drug_traceability_code,
          },
          {
            input_name: "confusing_signs",
            input_type: "checkbox",
            attr: "易混淆标识",
            val: this.ruleForm.confusing_signs,
          },
          {
            input_name: "limited_usage",
            input_type: "checkbox_text",
            attr: "限定用法",
            val: this.ruleForm.limited_usageInput,
          },
          {
            input_name: "limited_frequency",
            input_type: "checkbox_text",
            attr: "限定频次",
            val: this.ruleForm.limited_frequencyInput,
          },
          {
            input_name: "default_usage",
            input_type: "text",
            attr: "默认用法",
            val: this.ruleForm.default_usage,
          },
          {
            input_name: "default_frequency",
            input_type: "text",
            attr: "默认频次",
            val: this.ruleForm.default_frequency,
          },
          {
            input_name: "rounding_method",
            input_type: "text",
            attr: "取整方式",
            val: [
              this.ruleForm.rounding_method,
              this.ruleForm.rounding_methodT,
            ],
          },
          {
            input_name: "nursing_points",
            input_type: "text",
            attr: "护理要点",
            val: this.ruleForm.nursing_points,
          },
          {
            input_name: "patient_education",
            input_type: "text",
            attr: "患者宣教",
            val: this.ruleForm.patient_education,
          },
          {
            input_name: "key_drug_interpretation",
            input_type: "text",
            attr: "重点药物交代",
            val: this.ruleForm.key_drug_interpretation,
          },
        ]),
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$store.commit("isNewCommonNameVuex", true);
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.drawer = false;
        }
      });
    },
    commonNameEditAxios() {
      //编辑
      commonNameEdit({
        id: this.commonNameData.rowData.id,
        name: this.ruleForm.name,
        pinyin: this.ruleForm.pinyin,
        classify_id:
          this.ruleForm.classify_id[this.ruleForm.classify_id.length - 1],
        dosage_form_id: this.ruleForm.dosage_form,
        common_name_attr_val: JSON.stringify([
          {
            input_name: "antibacterial_grade",
            input_type: "text",
            attr: "抗菌药等级",
            val: this.ruleForm.antibacterial_grade,
          },
          {
            input_name: "skin_test",
            input_type: "text",
            attr: "是否皮试",
            val: this.ruleForm.skin_test,
          },
          {
            input_name: "skin_limit",
            input_type: "text",
            attr: "皮试限制",
            val: this.ruleForm.skin_limit,
          },
          {
            input_name: "aw_liquid_skin_test",
            input_type: "text",
            attr: "原液皮试",
            val: this.ruleForm.aw_liquid_skin_test,
          },
          {
            input_name: "antitumor_drug",
            input_type: "text",
            attr: "抗肿瘤药物",
            val: this.ruleForm.antitumor_drug,
          },
          {
            input_name: "high_risk_level",
            input_type: "text",
            attr: "高危等级",
            val: this.ruleForm.high_risk_level,
          },
          {
            input_name: "special_medicine",
            input_type: "text",
            attr: "特殊药品",
            val: this.ruleForm.special_medicine,
          },
          {
            input_name: "expense_account",
            input_type: "text",
            attr: "费用科目",
            val: this.ruleForm.expense_account,
          },
          {
            input_name: "tpn_drugs",
            input_type: "checkbox",
            attr: "tpn药物",
            val: this.ruleForm.tpn_drugs,
          },
          {
            input_name: "pdd_test",
            input_type: "checkbox",
            attr: "pdd试验",
            val: this.ruleForm.pdd_test,
          },
          {
            input_name: "executing_double_signature",
            input_type: "checkbox_text",
            attr: "执行双签名",
            val: this.ruleForm.executing_double_signatureInput,
          },
          {
            input_name: "drug_traceability_code",
            input_type: "checkbox",
            attr: "药品追溯码",
            val: this.ruleForm.drug_traceability_code,
          },
          {
            input_name: "confusing_signs",
            input_type: "checkbox",
            attr: "易混淆标识",
            val: this.ruleForm.confusing_signs,
          },
          {
            input_name: "limited_usage",
            input_type: "checkbox_text",
            attr: "限定用法",
            val: this.ruleForm.limited_usageInput,
          },
          {
            input_name: "limited_frequency",
            input_type: "checkbox_text",
            attr: "限定频次",
            val: this.ruleForm.limited_frequencyInput,
          },
          {
            input_name: "default_usage",
            input_type: "text",
            attr: "默认用法",
            val: this.ruleForm.default_usage,
          },
          {
            input_name: "default_frequency",
            input_type: "text",
            attr: "默认频次",
            val: this.ruleForm.default_frequency,
          },
          {
            input_name: "rounding_method",
            input_type: "text",
            attr: "取整方式",
            val: [
              this.ruleForm.rounding_method,
              this.ruleForm.rounding_methodT,
            ],
          },
          {
            input_name: "nursing_points",
            input_type: "text",
            attr: "护理要点",
            val: this.ruleForm.nursing_points,
          },
          {
            input_name: "patient_education",
            input_type: "text",
            attr: "患者宣教",
            val: this.ruleForm.patient_education,
          },
          {
            input_name: "key_drug_interpretation",
            input_type: "text",
            attr: "重点药物交代",
            val: this.ruleForm.key_drug_interpretation,
          },
        ]),
      }).then(res=>{
        console.log(res)
        this.$store.commit("isNewCommonNameVuex", true);
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.drawer = false;
      })
    },
    commonNameDetailAxios() {
      //详情
      commonNameDetail({
        id: this.commonNameData.rowData.id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.ruleForm.name = res.data.name;
          this.ruleForm.pinyin = res.data.pinyin;

          this.ruleForm.classify_id =
            res.data.classify_ids.map(Number)[res.data.classify_ids.length - 1];
          this.ruleForm.dosage_form = res.data.dosage_form;
          this.ruleForm.antibacterial_grade = res.data.antibacterial_grade.val;
          this.ruleForm.skin_test = res.data.skin_test.val;
          this.ruleForm.skin_limit = res.data.skin_limit.val;
          this.ruleForm.aw_liquid_skin_test = res.data.aw_liquid_skin_test.val;
          this.ruleForm.antitumor_drug = res.data.antitumor_drug.val;
          this.ruleForm.high_risk_level = res.data.high_risk_level.val;
          this.ruleForm.special_medicine = res.data.special_medicine.val;
          this.ruleForm.expense_account = res.data.expense_account.val;
          this.ruleForm.tpn_drugs = res.data.tpn_drugs.val;
          this.ruleForm.pdd_test = res.data.pdd_test.val;
          this.ruleForm.executing_double_signature =
            res.data.executing_double_signature.val == "" ? false : true;
          this.ruleForm.executing_double_signatureInput =
            res.data.executing_double_signature.val;
          this.ruleForm.drug_traceability_code =
            res.data.drug_traceability_code.val;
          this.ruleForm.confusing_signs =
            res.data.confusing_signs.val == ""
              ? []
              : res.data.confusing_signs.val;
          this.ruleForm.limited_usage =
            res.data.limited_usage.val == "" ? false : true;
          this.ruleForm.limited_usageInput = res.data.limited_usage.val;
          this.ruleForm.limited_frequency =
            res.data.limited_frequency.val == "" ? false : true;
          this.ruleForm.limited_frequencyInput = res.data.limited_frequency.val;
          this.ruleForm.default_usage = res.data.default_usage.val;
          this.ruleForm.default_frequency = res.data.default_frequency.val;
          this.ruleForm.rounding_method = res.data.rounding_method.val[0];
          this.ruleForm.rounding_methodT = res.data.rounding_method.val[1];

          this.ruleForm.nursing_points = res.data.nursing_points.val;
          this.ruleForm.patient_education = res.data.patient_education.val;
          this.ruleForm.key_drug_interpretation =
            res.data.key_drug_interpretation.val;
        }
      });
    },
    drugDosageAxios() {
      drugDosage().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.drugDosageData = res.data.list;
        }
      });
    },
    pinyinChange() {
      this.ruleForm.pinyin = pinyin.getCamelChars(this.ruleForm.name);
    },
    cancelClick() {
      this.drawer = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.commonNameData.rowData == null) {
            this.commonNameStoreAxios();
          } else {
            this.$confirm(
              "保存后，该商品名相关信息将会同步更新，是否确认保存该编辑内容？",
              "保存",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                iconClass: "el-icon-question",
              }
            )
              .then(() => {
                // 认证成功
                this.commonNameEditAxios()
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.drawerBox {
  padding: 24px 16px 50px 16px;
  box-sizing: border-box;
}
.drawerFooter {
  width: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 20px 0;
  box-sizing: border-box;
}
</style>
<style lang="css">
.NewCommonName .el-textarea__inner {
  height: 100%;
}
</style>
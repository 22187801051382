<template>
  <div class="pharmacist">
    <div v-if="$route.path == '/prescriptionAudit/pharmacist'">
      <div class="pharmacist_header">
        <div>
          <span class="header_title">处方ID</span>
          <el-input
            v-model="recipe_id"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 24px"
          ></el-input>
          <span class="header_title">问诊编号</span>
          <el-input
            v-model="rp_sn"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 24px"
          ></el-input>
          <span class="header_title">问诊名称</span>
          <el-input
            v-model="diagnose_name"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 24px"
          ></el-input>
          <span class="header_title">用户名称</span>
          <el-input
            v-model="user_name"
            placeholder="请输入"
            size="small"
            style="width: 200px"
          ></el-input>
        </div>
        <div style="margin-top: 16px">
          <span class="header_title">医生名称</span>
          <el-input
            v-model="doctor_name"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 24px"
          ></el-input>
          <span class="header_title">审核状态</span>
          <el-select
            v-model="status"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 24px"
          >
            <el-option label="全部" value="0"> </el-option>
            <el-option label="待审核" value="1"> </el-option>
            <el-option label="审核失败" value="2"> </el-option>
            <el-option label="审核通过" value="3"> </el-option>
          </el-select>

          <span class="header_title">创建时间</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            :clearable="false"
            value-format="yyyy-MM-dd"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            size="small"
            style="width: 200px; margin-right: 24px"
          >
          </el-date-picker>
          <el-button
            type="primary"
            size="small"
            style="margin-left: 146px"
            @click="searchClick()"
            >查询</el-button
          >
          <el-button size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="pharmacist_table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          height="calc(100vh - 298px)"
        >
          <el-table-column prop="id" label="处方ID"> </el-table-column>
          <el-table-column prop="rp_sn" label="问诊编号"> </el-table-column>
          <el-table-column prop="order_last_diagnose" label="问诊名称">
          </el-table-column>

          <el-table-column prop="user_real_name" label="用户名称">
          </el-table-column>
          <el-table-column prop="doctor_name" label="医生名称">
          </el-table-column>
          <el-table-column prop="status_text" label="审核状态">
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间"  width="150">
          </el-table-column>
          <el-table-column prop="audit_pharm_remark" label="审核备注">
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="pharmacistDetailClick(scope.row)"
                >审核详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout=" prev, pager, next, jumper, sizes,total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { reviewIndex } from "../../api/prescriptionAudit/physician";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
      recipe_id: "",
      rp_sn: "",
      diagnose_name: "",
      user_name: "",
      doctor_name: "",
      status: "0",
      time: "",
      pageSize: 10,
      currentPage: 1,
      total: 0,
    };
  },
  watch: {
    $route(val) {
      console.log(val);
      this.reviewIndexAxios();
      // this.commonNameListAxios();
    },
  },
  created() {
    this.reviewIndexAxios();
  },
  methods: {
    searchClick() {
      this.reviewIndexAxios();
    },
    resetClick() {
      this.recipe_id = "";
      this.rp_sn = "";
      this.diagnose_name = "";
      this.user_name = "";
      this.doctor_name = "";
      this.status = "0";
      this.time = "";
      this.pageSize = 10;
      this.currentPage = 1;
      this.reviewIndexAxios();
    },
    reviewIndexAxios() {
      reviewIndex({
        type: "2",
        recipe_id: this.recipe_id,
        rp_sn: this.rp_sn,
        diagnose_name: this.diagnose_name,
        user_name: this.user_name,
        doctor_name: this.doctor_name,
        status: this.status,
        start_date: this.time[0],
        end_date: this.time[1],
        per_page: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    pharmacistDetailClick(row) {
      this.$router.push({
        path: "/prescriptionAudit/pharmacist/pharmacistDetail",
        query: row,
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.reviewIndexAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.reviewIndexAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.pharmacist_header {
  padding: 16px;
  box-sizing: border-box;
  .header_title {
    display: inline-block;
    width: 60px;
    font-size: 14px;
    margin-right: 8px;
    text-align: left;
  }
}
</style>
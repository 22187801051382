<template>
  <div class="account">
    <div class="accountHeader">
      <div style="width: 100%">
        <span>搜索</span>
        <el-input v-model="infoVal" placeholder="输入姓名/手机号搜索" size="small" style="width: 200px; margin-right: 24px">
        </el-input>
        <span>角色</span>
        <el-select v-model="roleVal" placeholder="请选择" size="small" style="width: 160px; margin-right: 24px">
          <el-option v-for="item in roleData" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <span>状态</span>
        <el-select v-model="statusVal" placeholder="请选择" size="small" style="width: 160px; margin-right: 24px">
          <el-option label="启用" value="1"> </el-option>
          <el-option label="禁用" value="2"> </el-option>
        </el-select>
        <el-button type="primary" size="small" @click="searchClick">查询</el-button>
        <el-button size="small" @click="resetClick">重置</el-button>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="newAddClick">新建账号</el-button>
      </div>
    </div>
    <div class="accountTable">
      <el-table :data="tableData" stripe style="width: 100%" height="calc(100vh - 256px)">
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>

        <el-table-column prop="roles" label="角色">
          <template slot-scope="scope">
            <span v-for="item in scope.row.roles" :key="item.id">{{ item.name }}；</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="添加时间" width="150">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <div class="stateTag">
              {{ scope.row.admin_status == "1" ? "启用" : "禁用" }}
              <sup class="badge_content" :class="{ is_do: scope.row.admin_status == '2' }"></sup>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div>
              <el-button type="text" :class="{
                color2: scope.row.admin_status == '1',
                color1: scope.row.admin_status == '2',
              }" @click="adminSetStatusAxios(scope.row)">{{
    scope.row.admin_status == "1" ? "禁用" : "启用"
}}</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="editClick(scope.row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="adminDeleteAxios(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
        layout=" prev, pager, next, jumper, sizes,total" :total="total">
      </el-pagination>
    </div>

    <el-drawer :title="editStatus == null ? '新建账号' : '编辑账号'" :visible.sync="drawer" direction="rtl"
      :before-close="handleClose" :size="316">
      <div class="drawerContent">
        <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" size="small">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name" style="width: 208px"></el-input>
            </el-form-item>

            <el-form-item label="手机号" prop="phone">
              <el-input v-model="ruleForm.phone" style="width: 208px"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="ruleForm.password" style="width: 208px"></el-input>
            </el-form-item>

            <el-form-item label="角色" prop="role_ids">
              <el-select v-model="ruleForm.role_ids" multiple collapse-tags placeholder="请选择" style="width: 208px">
                <el-option v-for="item in roleData" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawerContent_footer">
          <el-button @click="handleClose" size="small">取消</el-button>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  roleGetRoleList,
  adminList,
  adminSetStatus,
  adminDelete,
  adminCreate,
  adminEdit,
} from "../../api/role/account";
let formData = {
  name: "",
  password: "",
  phone: "",
  role_ids: [],
};
export default {
  data() {
    return {
      tableData: [],

      infoVal: "",
      roleVal: "",
      roleData: [],
      statusVal: "",

      currentPage: 1,
      pageSize: 10,
      total: 0,

      drawer: false,
      ruleForm: JSON.parse(JSON.stringify(formData)),
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        // password: [{ required: true, message: "请输入" }],
        phone: [{ required: true, message: "请输入" }],
        role_ids: [{ required: true, message: "请选择" }],
      },

      editStatus: null,
    };
  },
  created() {
    this.adminListAxios();
    this.roleGetRoleListAxios();
  },
  methods: {
    adminEditAxios() {
      adminEdit({
        id: this.editStatus.id,
        name: this.ruleForm.name,
        password: this.ruleForm.password,
        phone: this.ruleForm.phone,
        role_ids: this.ruleForm.role_ids,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.drawer = false;
          this.adminListAxios();
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    adminCreateAxios() {
      adminCreate({
        name: this.ruleForm.name,
        password: this.ruleForm.password,
        phone: this.ruleForm.phone,
        role_ids: this.ruleForm.role_ids,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.drawer = false;
          this.adminListAxios();
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    editClick(row) {
      this.ruleForm.password = '';
      this.editStatus = row;
      this.drawer = true;
      this.ruleForm.name = row.name;
      this.ruleForm.phone = row.phone;
      let arr = [];
      row.roles.forEach((element) => {
        arr.push(element.id);
      });
      this.ruleForm.role_ids = arr;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editStatus == null) {
            this.adminCreateAxios();
          } else {
            this.adminEditAxios();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.drawer = false;
    },
    adminDeleteAxios(row) {
      this.$confirm("是否确认删除该账号？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          adminDelete({
            id: row.id,
          }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.adminListAxios();
              this.$message({
                message: res.msg,
                type: "success",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    adminSetStatusAxios(row) {
      adminSetStatus({
        id: row.id,
        status: row.admin_status == 1 ? 2 : 1,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.adminListAxios();
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    searchClick() {
      this.adminListAxios();
    },
    resetClick() {
      this.infoVal = "";
      this.roleVal = "";
      this.statusVal = "";
      this.pageSize = 10;
      this.currentPage = 1;
      this.adminListAxios();
    },
    roleGetRoleListAxios() {
      roleGetRoleList().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.roleData = res.data.list;
        }
      });
    },
    adminListAxios() {
      adminList({
        info: this.infoVal,
        role: this.roleVal,
        admin_status: this.statusVal,
        per_page: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    newAddClick() {
      this.ruleForm = JSON.parse(JSON.stringify(formData));
      this.ruleForm.password = 123456;
      this.editStatus = null;
      this.drawer = true;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.adminListAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.adminListAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.accountHeader {
  padding: 24px 16px 16px 16px;
  box-sizing: border-box;
  display: flex;

  span {
    font-size: 14px;
    color: #394359;
    margin-right: 8px;
  }
}

.color1 {
  color: #029b68;
}

.color2 {
  color: #de241b;
}

.drawerContent {
  padding: 24px 16px;
  box-sizing: border-box;
}

.drawerContent_footer {
  text-align: center;
  margin-top: 24px;
}
</style>
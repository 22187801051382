import req from '../../utils/http'
//职称列表
export const appoint = params => req("get", "/common/v1/appoint", params)
//第一执业机构
export const searchHospital = params => req("get", "/dic/v1/hospital/search", params)
//民族
export const getAll = params => req("get", "/dic/v1/nation/get_all", params)
//科室
export const doctorDept = params => req("get", "/clinic/v1/admin/doctor/dept", params)
//
export const doctorCreate = params => req("post", "/clinic/v1/admin/doctor/create", params)
//实名认证
export const doctorRealnameauth = params => req("post", "/clinic/v1/admin/doctor/realnameauth", params)

//验证码
export const smsSendCode = params => req("post", "/common/v1/sms/send_code", params)

//资质添加
export const doctorCertifyauth = params => req("post", "/clinic/v1/admin/doctor/certifyauth", params)


import req from '../../utils/http'
//角色列表
export const roleGetRoleList = params => req("get", "/auth/v1/admin/role/getRoleList", params)
//角色禁用启用
export const roleSetStatus = params => req("post", "/auth/v1/admin/role/setStatus", params)

//权限列表
export const operationGetTree = params => req("get", "/auth/v1/admin/menu/all", params)

//角色添加
export const roleAddRole = params => req("post", "/auth/v1/admin/role/addRole", params)
//角色编辑
export const roleEditRole = params => req("post", "/auth/v1/admin/role/editRole", params)
//角色删除
export const roleDelRole = params => req("post", "/auth/v1/admin/role/delRole", params)
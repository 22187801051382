import req from '../../utils/http'
//角色列表
export const roleGetRoleList = params => req("get", "/auth/v1/admin/role/getRoleList", params)
//账号列表
export const adminList = params => req("get", "/admin/v1/admin/list", params)
//账号禁用启用
export const adminSetStatus = params => req("post", "/admin/v1/admin/set_status", params)
//账号删除
export const adminDelete = params => req("post", "/admin/v1/admin/delete", params)
//账号添加
export const adminCreate = params => req("post", "/admin/v1/admin/create", params)
//账号编辑
export const adminEdit = params => req("post", "/admin/v1/admin/edit", params)
<template>
  <div class="loginLog">
    <div class="loginLog_head">
      <span class="span">医生ID</span>
      <el-input
        v-model="doctorId"
        placeholder="请输入"
        size="small"
        style="width: 200px; margin-right: 18px"
      ></el-input>
      <span class="span">医生名称</span>
      <el-input
        v-model="doctorName"
        placeholder="请输入"
        size="small"
        style="width: 200px; margin-right: 18px"
      ></el-input>
      <span class="span">登录时间</span>
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        size="small"
        style="width: 200px; margin-right: 18px"
      >
      </el-date-picker>
      <el-button type="primary" size="small" @click="searchClick"
        >查询</el-button
      >
      <el-button size="small" @click="resetClick">重置</el-button>
    </div>
    <div class="loginLog_table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        height="calc(100vh - 249px)"
      >
        <el-table-column prop="doctor_id" label="医生ID"> </el-table-column>
        <el-table-column prop="doctor_name" label="医生名称"> </el-table-column>
        <el-table-column prop="doctor_appoint_name" label="职称名称">
        </el-table-column>

        <el-table-column prop="doctor_clinic_name" label="医院名称">
        </el-table-column>
        <el-table-column prop="doctor_dept_name" label="科室名称">
        </el-table-column>
        <el-table-column prop="created_at" label="登录时间" width="150"> </el-table-column>

        <el-table-column label="登录医师预览" width="106">
          <template slot-scope="scope">
            <el-image
              style="width: 30px; height: 30px"
              :src="scope.row.doctor_avatar_url"
              :preview-src-list="[scope.row.doctor_avatar_url]"
            >
            </el-image>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout=" prev, pager, next, jumper, sizes,total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getLogList } from "../../api/log/log";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      tableData: [],

      input: "",

      doctorId: "",
      doctorName: "",
      date: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
    };
  },
  created() {
    this.getLogListAxios();
  },
  methods: {
    searchClick() {
      this.getLogListAxios();
    },
    resetClick() {
      this.pageSize = 10;
      this.currentPage = 1;
      this.doctorId = "";
      this.doctorName = "";
      this.date = [];
      this.getLogListAxios();
    },
    getLogListAxios() {
      getLogList({
        doctor_id: this.doctorId,
        doctor_name: this.doctorName,
        start_date: this.date[0],
        end_date: this.date[1],
        per_page: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.getLogListAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getLogListAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.loginLog_head {
  padding: 16px 30px;
  box-sizing: border-box;
  .span {
    display: inline-block;
    font-size: 14px;
    margin-right: 8px;
  }
}
</style>
<template>
  <div class="commodity">
    <div class="commodity_tree">
      <div class="tree_box">
        <el-tree
          ref="treeBox"
          class="treeClass"
          :data="drugTree"
          node-key="id"
          :props="defaultProps"
          :default-expanded-keys="ActivedrugTree"
          :default-checked-keys="ActivedrugTree"
          @node-click="handleNodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span  :class="{ isClass: data.id == activeTreeID }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="data.title "
                placement="right"
                :enterable="false"
              >
                <span class="span_ellipsis">
                  {{ data.title }}
                </span>
              </el-tooltip>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <!-- 通用名 -->
    <div class="commodity_nav">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { classifyList } from "../../api/drugs/drugs";
export default {
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "title",
        value: "id",
      },
      ActivedrugTree: [],
      drugTree: [],
      activeTreeID: null,
    };
  },
  created() {
    this.classifyListAxios();
  },
  methods: {
    classifyListAxios() {
      classifyList({
        type: "tree",
      }).then((res) => {
        if (res.code == 200) {
          this.drugTree = res.data;
          this.ActivedrugTree = [Number(this.$route.query.id)];
          this.activeTreeID = this.ActivedrugTree;
          console.log(this.ActivedrugTree);
        }
      });
    },
    handleNodeClick(data) {
      if (!data.children) {
        this.activeTreeID = data.id;
        this.$router.push({
          path: "/drugs/index/commonName",
          query: data,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.treeClass{
  width: 100%;
}
.commodity {
  height: 100%;
  background-color: #eef1f7;
  overflow: hidden;
}
.commodity_tree {
  float: left;
  width: 264px;
  height: 100%;
  margin-right: 16px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 22px 15px;
  .tree_box {
    height: 100%;
    overflow: scroll;
    box-sizing: border-box;
  }
}
.commodity_nav {
  float: right;
  width: calc(100% - 280px);
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
}
.isClass {
  color: #de241b;
}
.span_ellipsis {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
</style>
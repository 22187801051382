<template>
  <div class="login">
    <div class="login_box">
      <div class="login_boxDiv">
        <div style="text-align:center">

       
        <img
          src="../../assets/logo.png"
          alt=""
          style="height: 108px;margin:0 auto;"
        />
         </div>
        <div class="login_boxDiv_user">
          <el-form
            label-position="top"
            label-width="80px"
            :model="formLabelAlign"
            :rules="rules"
            ref="ruleForm"
          >
            <el-form-item label="账号" prop="userId">
              <el-input
                v-model="formLabelAlign.userId"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="formLabelAlign.password"
                placeholder="请输入密码"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="width: 100%; margin-top: 30px"
                @click="submitForm('ruleForm')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { adminLogin } from "../../api/login/login";
export default {
  data() {
    var validateUserId = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    var validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      formLabelAlign: {
        userId: "",
        password: "",
      },
      rules: {
        userId: [{ validator: validateUserId, trigger: "blur" }],
        password: [{ validator: validatePassword, trigger: "blur" }],
      },
    };
  },
  methods: {
    adminLoginAxios() {
      adminLogin({
        username: this.formLabelAlign.userId,
        password: this.formLabelAlign.password,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$cookies.set("jwtToken", res.data.token, { expires: 7 });
          this.$router.push("/")
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.adminLoginAxios();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  // display: block;
  height: 100vh;
  background-image: url("../../assets/images/login_bg@2x.png");
  background-size: calc(100% - 576px) 100%;
  background-repeat: no-repeat;
  text-align: right;
  .login_box {
    display: inline-block;
    width: 576px;
    height: 100vh;
    padding: calc((100vh - 546px) / 2) 63px;
    box-sizing: border-box;
    .login_boxDiv {
      width: 100%;
      height: 546px;
      text-align: left;
      .login_boxDiv_user {
        padding-top: 100px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
<template>
  <div class="tradeName">
    <div class="tradeName_header">
      <el-row>
        <el-col :span="20">
          <div>
            <span class="span">搜索</span>
            <el-input
              v-model="nameVal"
              placeholder="输入编码/商品名进行搜索"
              size="small"
              style="width: 226px; margin-right: 24px"
            ></el-input>
            <span class="span">搜索</span>
            <el-select v-model="statusVal" placeholder="请选择" size="small">
              <el-option label="启用" value="1"> </el-option>
              <el-option label="禁用" value="2"> </el-option>
            </el-select>

            <el-button
              type="primary"
              size="small"
              style="margin-left: 46px"
              @click="searchClick"
              >查询</el-button
            >
            <el-button size="small" @click="resetClick">重置</el-button>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="text-align: right">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click="newAddClick"
              >新增商品名</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tradeName_table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        height="calc(100vh - 264px)"
      >
        <el-table-column prop="sku_sn" label="编码"> </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.image_url"
              :preview-src-list="[scope.row.image_url]"
              style="width: 60px; height: 60px"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名">
          <template slot-scope="scope">
            <div>
              <p>{{ scope.row.name }}</p>
              <span style="margin-right: 12px">{{ scope.row.norm }}</span>
              <span>{{ scope.row.expiration_date }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="unit" label="单位"> </el-table-column>

        <el-table-column prop="factory" label="厂家"> </el-table-column>

        <el-table-column prop="production_address" label="产地">
        </el-table-column>
        <el-table-column prop="norm" label="单价"> </el-table-column>
        <el-table-column prop="approval_number" label="批准文号">
        </el-table-column>
        <el-table-column prop="created_at" label="添加时间" width="150">
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="150">
        </el-table-column>

        <el-table-column label="状态">
          <template slot-scope="scope">
            <div class="stateTag">
              {{ scope.row.status == 1 ? "启用" : "禁用" }}
              <sup
                class="badge_content"
                :class="{ is_do: scope.row.status == 2 }"
              ></sup>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                :class="{
                  color2: scope.row.status == 1,
                  color1: scope.row.status == 2,
                }"
                @click="statusClick(scope.row)"
                >{{ scope.row.status == 1 ? "禁用" : "启用" }}</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="editClick(scope.row)"
                >编辑</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout=" prev, pager, next, jumper, sizes,total"
        :total="total"
      >
      </el-pagination>
    </div>
    <NewTradeName
      :tradeName="tradeName"
      @drawerChange="drawerChange"
    ></NewTradeName>
  </div>
</template>
<script>
import NewTradeName from "./components/NewTradeName.vue";
import { informationList, informationUpdate } from "../../api/drugs/drugs";
export default {
  components: {
    NewTradeName,
  },
  data() {
    return {
      tableData: [],
      tradeName: {
        drawerIs: false,
        rowData: null,
      },

      nameVal: "",
      statusVal: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  watch: {
    "$store.state.commonNameDataVuex"(val) {
      if (val) {
        console.log(val);
        this.informationListAxios();
      }
    },
  },
  created() {
    // console.log(this.$store.state.commonNameData);
    this.informationListAxios();
  },
  mounted() {},
  methods: {
    searchClick() {
      this.informationListAxios();
    },
    resetClick() {
      this.nameVal = "";
      this.statusVal = "";
      this.pageSize = 10;
      this.currentPage = 1;
      this.informationListAxios();
    },
    statusClick(row) {
      //启用禁用
      informationUpdate({
        id: row.id,
        status: row.status == 1 ? 2 : 1,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.informationListAxios();
        }
      });
    },
    informationListAxios() {
      informationList({
        common_name_id: this.$store.state.commonNameData.id,
        name: this.nameVal,
        status: this.statusVal,
        per_page: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    drawerChange(val) {
      this.tradeName.drawerIs = val;
      this.informationListAxios();
    },
    newAddClick() {
      this.tradeName = {
        drawerIs: true,
        rowData: null,
      };
    },
    editClick(row) {
      this.tradeName = {
        drawerIs: true,
        rowData: row,
      };
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.informationListAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.informationListAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.tradeName_header {
  padding: 24px 16px;
  box-sizing: border-box;
  .span {
    font-size: 14px;
    color: #394359;
    margin-right: 8px;
  }
}
.color1 {
  color: #029b68;
}
.color2 {
  color: #de241b;
}
</style>
<template>
  <div class="commonName">
    <div class="commonName_header">
      <el-row>
        <el-col :span="20">
          <div>
            <span class="span">搜索</span>
            <el-input
              v-model="nameVal"
              placeholder="输入通用名进行搜索"
              size="small"
              style="width: 226px; margin-right: 24px"
            ></el-input>
            <span class="span">状态</span>
            <el-select v-model="statusVal" placeholder="请选择" size="small">
              <el-option label="启用" value="1"> </el-option>
              <el-option label="禁用" value="2"> </el-option>
            </el-select>

            <el-button
              type="primary"
              size="small"
              style="margin-left: 46px"
              @click="searchClick"
              >查询</el-button
            >
            <el-button size="small" @click="resetClick">重置</el-button>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="text-align: right">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click="newAddClick"
              >新增通用名</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="commonName_table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        height="calc(100vh - 264px)"
      >
        <el-table-column prop="name" label="通用名"> </el-table-column>
        <el-table-column prop="dosage_form" label="剂型"> </el-table-column>
        <el-table-column prop="created_at" label="添加时间"  width="150"> </el-table-column>

        <el-table-column prop="updated_at" label="更新时间"  width="150"> </el-table-column>
        <el-table-column prop="address" label="状态">
          <template slot-scope="scope">
            <div class="stateTag">
              {{ scope.row.status == 1 ? "启用" : "禁用" }}
              <sup
                class="badge_content"
                :class="{ is_do: scope.row.status == 2 }"
              ></sup>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                :class="{
                  color2: scope.row.status == 1,
                  color1: scope.row.status == 2,
                }"
                @click="statusClick(scope.row)"
                >{{ scope.row.status == 1 ? "禁用" : "启用" }}</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="editClick(scope.row)"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="goToTradeClick(scope.row)"
                >查看商品</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="prev, pager, next, jumper, sizes , total"
        :total="total"
      >
      </el-pagination>
    </div>
    <NewCommonName
      :commonNameData="commonNameData"
      @drawerChange="drawerChange"
    ></NewCommonName>
  </div>
</template>
<script>
import NewCommonName from "./components/NewCommonName.vue";
import { commonNameList, commonNameUpdate } from "../../api/drugs/drugs";
export default {
  components: {
    NewCommonName,
  },
  data() {
    return {
      tableData: [],
      commonNameData: {
        drawerIs: false,
        rowData: null,
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      nameVal: "",
      statusVal: "",
    };
  },
  watch: {
    $route() {
      console.log(this.$route.query);
      this.commonNameListAxios();
    },
    "$store.state.isNewCommonName"(val) {
      if (val) {
        this.commonNameListAxios();
        this.$store.commit("isNewCommonNameVuex", false);
      }
    },
  },
  created() {
    this.commonNameListAxios();
  },
  methods: {
    statusClick(row) {
      commonNameUpdate({
        id: row.id,
        status: row.status == "1" ? "2" : "1",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.commonNameListAxios();
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    searchClick() {
      this.commonNameListAxios();
    },
    resetClick() {
      this.nameVal = "";
      this.statusVal = "";
      this.pageSize = 10;
      this.currentPage = 1;
      this.commonNameListAxios();
    },
    commonNameListAxios() {
      commonNameList({
        name: this.nameVal,
        status: this.statusVal,
        classify_id: this.$route.query.id,
        per_page: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    drawerChange(val) {
      this.commonNameData.drawerIs = val;
    },
    newAddClick() {
      this.commonNameData = {
        drawerIs: true,
        rowData: null,
      };
    },
    editClick(row) {
      this.commonNameData = {
        drawerIs: true,
        rowData: row,
      };
    },
    goToTradeClick(row) {
      console.log(row)
      // 查看商品
      this.$store.commit('commonNameDataVuex',row)
      this.$router.push({
        path: "/drugs/index/tradeName",
        query:this.$route.query
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.commonNameListAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.commonNameListAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.commonName_header {
  padding: 24px 16px;
  box-sizing: border-box;
  .span {
    font-size: 14px;
    color: #394359;
    margin-right: 8px;
  }
}
.color1 {
  color: #029b68;
}
.color2 {
  color: #de241b;
}
</style>
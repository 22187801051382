<template>
  <div class="prescriptionSettings">
    <div class="prescriptionSettings_box">
      <span>处方有效期</span>
      <el-input
        v-model="recipelVal"
        placeholder="请输入"
        size="small"
        style="width: 200px; margin-right: 8px"
      ></el-input>
      <span>天</span>
      <p><span>* </span>处方审核通过后的有效时间</p>
    </div>

    <div class="prescriptionSettings_box">
      <span>病历有效期</span>
      <el-input
        v-model="caseVal"
        placeholder="请输入"
        size="small"
        style="width: 200px; margin-right: 8px"
      ></el-input>
      <span>天</span>
      <p><span>* </span>患者提交病情描述后的有效时间</p>
    </div>
  </div>
</template>
<script>
import { setEditsetRecipelist } from "../../api/prescriptionSettings/prescriptionSettings";
export default {
  data() {
    return {
      recipelVal: "",
      caseVal: "",
    };
  },
  methods: {
    setEditsetRecipelistAxios() {
      setEditsetRecipelist({
        recipel_period_validity: "",
        case_period_validity: "",
      }).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.prescriptionSettings {
  padding: 24px 16px;
  box-sizing: border-box;
}
.prescriptionSettings_box {
  font-size: 14px;
  color: #394359;
  margin-bottom: 36px;
  span {
    margin-right: 8px;
  }
  p {
    color: #9fa9bd;
    margin: 8px 0;
    span {
      color: #de241b;
      margin: 0;
    }
  }
}
</style>
<template>
  <div class="role">
    <div class="roleHeader">
      <div style="width: 100%">
        <span>搜索</span>
        <el-input
          v-model="nameVal"
          placeholder="输入角色进行搜索"
          size="small"
          style="width: 200px; margin-right: 24px"
        ></el-input>
        <span>状态</span>
        <el-select
          v-model="statusVal"
          placeholder="请选择"
          size="small"
          style="width: 160px; margin-right: 24px"
        >
          <el-option label="启用" value="1"> </el-option>
          <el-option label="禁用" value="2"> </el-option>
        </el-select>
        <el-button type="primary" size="small" @click="searchClick"
          >查询</el-button
        >
        <el-button size="small" @click="resetClick">重置</el-button>
      </div>

      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="newAddClick"
          >新建角色</el-button
        >
      </div>
    </div>
    <div class="roleTable">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        height="calc(100vh - 256px)"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="name" label="角色"> </el-table-column>
        <el-table-column prop="created_at" label="添加时间" width="150">
        </el-table-column>
        <el-table-column prop="address" label="状态">
          <template slot-scope="scope">
            <div class="stateTag">
              {{ scope.row.status }}
              <sup
                class="badge_content"
                :class="{ is_do: scope.row.status == '停用' }"
              ></sup>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                :class="{
                  color2: scope.row.status == '启用',
                  color1: scope.row.status == '停用',
                }"
                @click="roleSetStatusAxios(scope.row)"
                >{{ scope.row.status == "启用" ? "停用" : "启用" }}</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="editClick(scope.row)"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="roleDelRoleAxios(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout=" prev, pager, next, jumper, sizes,total"
        :total="total"
        :size="450"
      >
      </el-pagination>
    </div>

    <!-- //弹窗 -->
    <el-drawer
      :title="editStatus == null ? '新建角色' : '编辑角色'"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="drawerContent">
        <div class="drawerContent_sroll">
          <div class="drawerContent_box">
            <el-form
              :model="numberValidateForm"
              ref="numberValidateForm"
              size="small"
            >
              <el-form-item
                label="角色名称"
                prop="roleVal"
                :rules="[{ required: true, message: '角色名称不能为空' }]"
              >
                <el-input
                  v-model.number="numberValidateForm.roleVal"
                  style="width: 208px"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="后台权限"
                prop="htqx"
                :rules="[{ required: true, message: '请选择后台权限' }]"
              >
              </el-form-item>
            </el-form>
          </div>
          <div class="drawerContent_tree">
            <el-tree
              ref="tree"
              :data="treeData"
              :props="props"
              node-key="id"
              show-checkbox
              default-expand-all
              @check="handleCheckChange"
            >
            </el-tree>
          </div>
        </div>
        <div class="drawerContent_footer">
          <el-button @click="handleClose" size="small">取消</el-button>
          <el-button
            type="primary"
            size="small"
            @click="submitForm('numberValidateForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  roleGetRoleList,
  roleSetStatus,
  operationGetTree,
  roleAddRole,
  roleEditRole,
  roleDelRole,
} from "../../api/role/role";
let formData = {
  roleVal: "",
  htqx: [],
};
export default {
  data() {
    return {
      tableData: [],

      nameVal: "",
      statusVal: "",

      currentPage: 1,
      pageSize: 10,
      total: 0,

      drawer: false,
      numberValidateForm: JSON.parse(JSON.stringify(formData)),

      props: {
        label: "name",
        value: "id",
        children: "children",
      },
      treeData: [],

      editStatus: null,
    };
  },
  created() {
    this.roleGetRoleListAxios();
    this.operationGetTreeAxios();
  },
  methods: {
    roleDelRoleAxios(row) {
      this.$confirm("是否确认删除该角色？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          roleDelRole({
            role_id: row.id,
          }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.roleGetRoleListAxios();
              this.$message({
                message: res.msg,
                type: "success",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editClick(row) {
      this.drawer = true;
      this.$nextTick(() => {
        this.numberValidateForm.roleVal = row.name;
        this.numberValidateForm.htqx = row.permission.map(String);
        this.$refs.tree.setCheckedKeys(row.permission);
        this.editStatus = row;
        console.log(row.permission);
      });
    },
    roleEditRoleAxios() {
      roleEditRole({
        id: this.editStatus.id,
        name: this.numberValidateForm.roleVal,
        operation_ids: this.numberValidateForm.htqx,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.roleGetRoleListAxios();
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.drawer = false;
        }
      });
    },
    roleAddRoleAxios() {
      roleAddRole({
        name: this.numberValidateForm.roleVal,
        operation_ids: this.numberValidateForm.htqx,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.roleGetRoleListAxios();
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.drawer = false;
        }
      });
    },
    operationGetTreeAxios() {
      operationGetTree().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.treeData = res.data;
        }
      });
    },
    handleCheckChange(data, checked, indeterminate) {
      this.$nextTick(() => {
        console.log(this.$refs.tree.getCheckedKeys());
        this.numberValidateForm.htqx = this.$refs.tree.getCheckedKeys();
        this.$refs.numberValidateForm.validate((valid) => {});
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editStatus == null) {
            this.roleAddRoleAxios();
          } else {
            this.roleEditRoleAxios();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.drawer = false;
    },
    roleSetStatusAxios(row) {
      roleSetStatus({
        role_id: row.id,
        status: row.status == "启用" ? "2" : "1",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.roleGetRoleListAxios();
        }
      });
    },
    searchClick() {
      this.roleGetRoleListAxios();
    },
    resetClick() {
      this.nameVal = "";
      this.statusVal = "";
      this.currentPage = 1;
      this.pageSize = 10;
      this.roleGetRoleListAxios();
    },
    roleGetRoleListAxios() {
      roleGetRoleList({
        name: this.nameVal,
        status: this.statusVal,
        per_page: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    newAddClick() {
      this.numberValidateForm = JSON.parse(JSON.stringify(formData));
      this.drawer = true;
      this.editStatus = null;
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = 1;
      this.pageSize = val;
      this.roleGetRoleListAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.roleGetRoleListAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.roleHeader {
  padding: 24px 16px 16px 16px;
  box-sizing: border-box;
  display: flex;
  span {
    font-size: 14px;
    color: #394359;
    margin-right: 8px;
  }
}
.color1 {
  color: #029b68;
}
.color2 {
  color: #de241b;
}
.drawerContent {
  position: relative;
  height: calc(100vh - 52px);
}
.drawerContent_box {
  padding: 24px 16px 0px 16px;
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid #c9cada;
}
.drawerContent_tree {
  padding: 0 16px 0px 16px;
  box-sizing: border-box;
}
.drawerContent_footer {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #fff;
  height: 50px;
  line-height: 50px;
}
.drawerContent_sroll {
  height: calc(100vh - 102px);
  overflow: auto;
}
</style>
<template>
  <div class="query">
    <div v-if="$route.path == '/physician/query'">
      <div class="query_header">
        <div>
          <span>姓名</span>
          <el-input
            v-model="nameVal"
            placeholder="请输入"
            size="small"
            style="width: 200px; margin-right: 24px"
          ></el-input>

          <span>角色</span>

          <el-select
            v-model="typeVal"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 24px"
          >
            <el-option label="医生" value="1"> </el-option>
            <el-option label="药师" value="3"> </el-option>
          </el-select>

          <span>职称</span>
          <el-select
            v-model="appointVal"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 24px"
          >
            <el-option
              v-for="item in appointData"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <span>医师第一执业机构</span>

          <el-autocomplete
            class="inline-input"
            v-model="firstClinicIdVal"
            :fetch-suggestions="searchHospitalAxios"
            placeholder="请输入内容"
            :trigger-on-focus="false"
            @select="handleSelect"
            style="width: 200px"
            size="small"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.hotName }}</div>
            </template>
          </el-autocomplete>
        </div>

        <div style="margin: 16px 0">
          <span>学历</span>
          <el-select
            v-model="educationVal"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 24px"
          >
            <el-option label="小学" value="1"></el-option>
            <el-option label="初中" value="2"></el-option>
            <el-option label="中专" value="3"></el-option>
            <el-option label="高中" value="4"></el-option>
            <el-option label="大专" value="5"></el-option>
            <el-option label="本科" value="6"></el-option>
            <el-option label="硕士" value="7"></el-option>
            <el-option label="博士" value="8"></el-option>
          </el-select>

          <span>民族</span>

          <el-select
            v-model="nationVal"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 24px"
          >
            <el-option
              v-for="item in nationData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span>性别</span>
          <el-select
            v-model="sexVal"
            placeholder="请选择"
            size="small"
            style="width: 200px; margin-right: 24px"
          >
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
            <el-option label="未知" value="3"></el-option>
          </el-select>
          <span style="width: 112px">编制科室名称</span>
          <el-select
            v-model="deptVal"
            placeholder="请选择"
            size="small"
            style="width: 200px"
          >
            <el-option
              v-for="item in deptData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="btn">
          <el-button type="primary" size="small" @click="searchClick"
            >查询</el-button
          >
          <el-button size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="query_table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          height="calc(100vh - 344px)"
        >
          <el-table-column prop="id" label="医师/药师ID"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="type" label="角色"> </el-table-column>

          <el-table-column prop="education" label="学历"> </el-table-column>
          <el-table-column prop="appoint_name" label="职称名称">
          </el-table-column>
          <el-table-column prop="sex" label="性别"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="queryDetailClick(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding-top: 8px; box-sizing: border-box; text-align: right">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout=" prev, pager, next, jumper, sizes,total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 查看详情 -->
    <router-view></router-view>
  </div>
</template>
<script>
import {
  appoint,
  searchHospital,
  getAll,
  doctorDept,
  doctorGetlist,
} from "../../api/physician/query";
export default {
  data() {
    return {
      tableData: [],
      nameVal: "",
      typeVal: "",
      appointData: [],
      appointVal: "",
      firstClinicIdData: [],
      firstClinicIdVal: "",
      firstClinicIdValId: "",
      educationVal: "",
      nationData: [],
      nationVal: "",
      sexVal: "",
      deptData: [],
      deptVal: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  watch: {
    $route() {
      if (this.$route.path == "/physician/query") {
        this.appointAxios();
        this.getAllAxios();
        this.doctorDeptAxios();
        this.doctorGetlistAxios();

        // this.searchHospitalAxios();
      }
    },
  },
  created() {
    if (this.$route.path == "/physician/query") {
      this.appointAxios();
      this.getAllAxios();
      this.doctorDeptAxios();
      this.doctorGetlistAxios();
      // this.searchHospitalAxios();
    }
  },
  methods: {
    searchClick() {
      this.doctorGetlistAxios();
    },
    resetClick() {
      this.nameVal = "";
      this.typeVal = "";
      this.appointVal = "";
      this.nationVal = "";
      this.educationVal = "";
      this.firstClinicIdValId = "";
      this.sexVal = "";
      this.deptVal = "";
      this.currentPage = 1;
      this.pageSize = 10;
      this.doctorGetlistAxios();
    },
    doctorGetlistAxios() {
      doctorGetlist({
        name: this.nameVal,
        type: this.typeVal,
        appoint_code: this.appointVal,
        nation: this.nationVal,
        education: this.educationVal,
        first_clinic_id: this.firstClinicIdValId,
        sex: this.sexVal,
        dept_id: this.deptVal,
        page: this.currentPage,
        per_page: this.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    doctorDeptAxios() {
      doctorDept().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.deptData = res.data;
        }
      });
    },
    getAllAxios() {
      getAll().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.nationData = res.data;
        }
      });
    },
    searchHospitalAxios(queryString, cb) {
      searchHospital({
        hos_name: this.firstClinicIdVal,
      }).then((res) => {
        console.log(res.data.data);
        if (res.code == 200) {
          cb(res.data.data);
        }
      });
    },
    handleSelect(val) {
      console.log(val);
      this.firstClinicIdValId = val.id;
      this.firstClinicIdVal = val.hotName;
      //  first_clinic_id:this.ruleForm.firstclinicid,
      //   first_clinic_name:this.ruleForm.first_clinic_name,
    },
    appointAxios() {
      appoint().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.appointData = res.data;
        }
      });
    },
    queryDetailClick(row) {
      this.$router.push({
        path: "/physician/query/queryDetail",
        query: row,
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = 1;
      this.pageSize = val;
      this.doctorGetlistAxios();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.doctorGetlistAxios();
    },
  },
};
</script>
<style lang="scss" scoped>
.query_header {
  padding: 16px;
  box-sizing: border-box;
  span {
    display: inline-block;
    margin-right: 8px;
    font-size: 14px;
  }
  .btn {
    width: 1100px;
    text-align: right;
  }
}
.query_table {
}
</style>
<template>
  <div class="detail">
    <div class="headerBar" v-if="certify.status == 4">
      <i class="el-icon-info"></i>
      已认证通过
    </div>
    <div class="headerBar wtg" v-if="certify.status == 3">
      <i class="el-icon-info"></i>
      认证未通过,{{ certify.cause }}
    </div>
    <div
      class="detailBox"
      :class="{ detail_style: certify.status != 3 || certify.status != 4 }"
    >
      <div class="titleHead">
        <span></span>
        <h4>基本信息</h4>
      </div>
      <div>
        <div style="margin-top: 24px">
          <div class="formBox">
            <span style="width: 56px">头像</span>
            <div style="width: 200px">
              <el-avatar
                :size="32"
                :src="certifyinfoData.avatar_url"
              ></el-avatar>
            </div>
          </div>
          <div class="formBox">
            <span style="width: 84px">姓名</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.name"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
          <div class="formBox">
            <span style="width: 56px">性别</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.sex_text"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
        </div>
        <div style="margin-top: 24px">
          <div class="formBox">
            <span style="width: 56px">身份证号</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.id_card"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
          <div class="formBox">
            <span style="width: 84px">手机号</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.phone"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
          <div class="formBox">
            <span style="width: 56px">执业类型</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.type"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
        </div>
        <div style="margin-top: 24px">
          <div class="formBox">
            <span style="width: 56px">职称</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.appoint.name"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
          <div class="formBox">
            <span style="width: 84px">第一执业机构</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.first_clinic_name"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
          <div class="formBox">
            <span style="width: 56px">所在机构</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.clinic.name"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
        </div>
        <div style="margin-top: 24px">
          <div class="formBox">
            <span style="width: 56px">科室</span>
            <div style="width: 200px">
              <el-input
                v-model="certifyinfoData.dept.name"
                size="small"
                disabled
              ></el-input>
            </div>
          </div>
        </div>
        <div style="margin-top: 24px">
          <div class="formBox">
            <span style="width: 56px; vertical-align: top">擅长专业</span>
            <div style="width: 842px">
              <el-input
                type="textarea"
                v-model="certifyinfoData.speciality"
                show-word-limit
                disabled
              >
              </el-input>
            </div>
          </div>
        </div>
        <div style="margin-top: 24px; margin-bottom: 32px">
          <div class="formBox">
            <span style="width: 56px; vertical-align: top">个人简介</span>
            <div style="width: 842px">
              <el-input
                type="textarea"
                v-model="certifyinfoData.introduce"
                show-word-limit
                disabled
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="titleHead">
        <span></span>
        <h4>资质认证</h4>
      </div>
      <div style="margin-top: 24px">
        <el-table :data="tableData" stripe style="width: 910px">
          <el-table-column prop="name" label="证件类型"> </el-table-column>
          <el-table-column label="证件照片">
            <template slot-scope="scope">
              <el-image
                :src="scope.row.url"
                :preview-src-list="[scope.row.url]"
                fit="cover"
                style="width: 170px; height: 90px"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="证件号">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.number"
                placeholder="请输入"
                size="small"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="发证日期">
            <template slot-scope="scope">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="scope.row.date"
                type="date"
                size="small"
                style="width: 100%"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="证件查询结果">
            <template slot-scope="scope">
              <div class="button" v-if="scope.row.srcUrl == ''">
                <el-button
                  type="primary"
                  size="small"
                  @click="uploadClick(scope.row)"
                  >点击本地导入</el-button
                >
              </div>
              <div class="images" v-else>
                <img :src="scope.row.srcUrl" alt="" />
                <div class="zIndexBox">
                  <div @click="uploadClick(scope.row)">点击重新上传</div>
                  <img
                    src="../../../assets/images/sc.png"
                    alt=""
                    @click="delClick(scope.row)"
                  />
                </div>
              </div>

              <input
                v-if="isFile"
                name="file"
                v-show="false"
                ref="fileRef"
                class="fileRef"
                type="file"
                accept="image/png,image/gif,image/jpeg"
                @change="fileChange"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="footerBar" v-if="certify.status != 3 && certify.status != 4">
      <el-button size="small" @click="failureClick">认证失败</el-button>
      <el-button type="primary" size="small" @click="basisClick"
        >认证通过</el-button
      >
    </div>

    <el-dialog title="认证失败原因" :visible.sync="dialogVisible" width="434px">
      <div>
        <div>
          <el-radio v-model="radio" label="身份信息与证件照片不匹配"
            >身份信息与证件照片不匹配</el-radio
          >
        </div>
        <div style="margin: 16px 0">
          <el-radio v-model="radio" label="证件号查询无数据"
            >证件号查询无数据</el-radio
          >
        </div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          placeholder="请输入备注说明"
          v-model="textarea"
        >
        </el-input>
        <div class="dialog_footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="okClick"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import sctx from "../../../assets/images/sctx.png";
import {
  reviewCertifyinfo,
  reviewCertifyreview,
} from "../../../api/doctor/audit";
import axios from "axios";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      textarea: "",
      dialogVisible: false,
      radio: "身份信息与证件照片不匹配",
      tableData: [],
      certifyinfoData: {
        appoint: "",
        clinic: "",
        dept: "",
      },
      isFile: true,
      certify: {},
      activeRow: {},
      statusN: 4,
    };
  },
  created() {
    this.reviewCertifyinfoAxios();
  },
  methods: {
    reviewCertifyreviewAxios() {
      reviewCertifyreview({
        id: this.$route.query.id,
        status: this.statusN,
        certificate_sn: this.tableData[0].number,
        certificate_result: this.tableData[0].id,
        certificate_date: this.tableData[0].date,
        practice_sn: this.tableData[1].number,
        practice_result: this.tableData[1].id,
        practice_date: this.tableData[1].date,
        title_sn: this.tableData[2].number,
        title_result: this.tableData[2].id,
        title_date: this.tableData[2].date,
        cause: this.radio,
        remark: this.textarea,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.reviewCertifyinfoAxios();
          this.dialogVisible = false;
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    uploadClick(row) {
      this.$nextTick(() => {
        // this.isFile = true;
        this.activeRow = row;
        let dom = document.querySelector(".fileRef");
        dom.dispatchEvent(new MouseEvent("click"));
      });
    },
    fileChange(event) {
      // 上传文件
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      // formData.append("type", "goods_image");

      document.querySelector(".fileRef").value = "";
      if (formData.get("image")) {
        axios
          .post(
            this.$getAction.api + "/common/v1/admin/file/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "JWT-TOKEN": this.$cookies.get("jwtToken"),
                // ticket: this.$cookies.get("ticket"),
              },
            }
          )
          .then((res) => {
            // this.qualification
            console.log(this.activeRow);
            console.log(res.data.data.url);
            this.activeRow.srcUrl = res.data.data.url;
            this.activeRow.id = res.data.data.id;
          });
      }
    },
    delClick(row) {
      row.srcUrl = "";
      row.id = "";
      console.log(row);
    },
    reviewCertifyinfoAxios() {
      reviewCertifyinfo({
        id: this.$route.query.id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.certifyinfoData = res.data;
          this.certify = res.data.certify;
          let array = [
            {
              name: "资格证",
              url: res.data.certify.certificate_image,
              number: res.data.certify.certificate_sn,
              srcUrl: res.data.certify.certificate_result,
              date: res.data.certify.certificate_date,
            },
            {
              name: "职业证",
              url: res.data.certify.practice_image,
              number: res.data.certify.practice_sn,
              srcUrl: res.data.certify.practice_result,
              date: res.data.certify.practice_date,
            },
            {
              name: "职称证",
              url: res.data.certify.title_image,
              number: res.data.certify.title_sn,
              srcUrl: res.data.certify.title_result,
              date: res.data.certify.title_date,
            },
          ];
          // res.data.certify.forEach(element => {

          // });

          this.tableData = array;
        }
      });
    },
    failureClick() {
      this.dialogVisible = true;
      this.statusN = 3;
    },
    okClick() {
      this.reviewCertifyreviewAxios();
    },
    basisClick() {
      this.statusN = 4;
      this.$confirm("是否确定认证通过？", "是否通过", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        iconClass: "el-icon-question",
      })
        .then(() => {
          // 认证成功
          this.reviewCertifyreviewAxios();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  height: calc(100vh - 144px);
  padding: 40px 16px 16px 16px;
  box-sizing: border-box;
  position: relative;
}
.detail_style {
  height: calc(100vh - 144px);
  padding: 0 16px 16px 16px;
}
.headerBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: rgba($color: #28b28b, $alpha: 0.2);
  font-size: 14px;
  color: #394359;
  padding: 0 16px;
  box-sizing: border-box;
  i {
    color: #28b28b;
  }
}
.wtg {
  background-color: rgba($color: #de241b, $alpha: 0.2);
  color: #de241b;
  border: 1px solid #de241b;
  i {
    color: #de241b;
  }
}
.detailBox {
  height: calc(100vh - 266px);
  overflow: scroll;
  margin-top: 20px;
}
.footerBar {
  width: 910px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  padding: 12px 0;
  box-sizing: border-box;
}
.titleHead {
  span {
    width: 7px;
    height: 20px;
    display: inline-block;
    background-color: #2a2f63;
    vertical-align: middle;
    margin-right: 8px;
  }
  h4 {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }
}
.formBox {
  display: inline-block;
  //   justify-content:center;
  span {
    display: inline-block;
    margin-right: 8px;
    text-align: right;
    font-size: 14px;
    vertical-align: middle;
  }
  div {
    display: inline-block;
    vertical-align: middle;
    margin-right: 40px;
  }
}
.image_slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  background: #f5f7fa;
  color: #909399;
}
.button button {
  margin: 0;
  padding: 0;
  border: 1px solid #e69a41; //自定义边框
  //   outline: none; //消除默认点击蓝色边框效果
  background: none;
  color: #e69a41;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}
.images {
  width: 170px;
  height: 90px;
  position: relative;
  // overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  img:last-child {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 9;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  div {
    position: absolute;
    width: 100%;
    height: 20px;
    line-height: 20px;
    bottom: 0;
    left: 0;
    color: #fff;
    background-color: #de241b;
    text-align: center;
    font-size: 14px;
  }
  .zIndexBox {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    div {
      cursor: pointer;
    }
  }
  .zIndexBox:hover {
    opacity: 1;
  }
  //   i{
  //       position: absolute;
  //       top: 0;
  //       right: 0;
  //       z-index: 9;
  //       color: #fff;
  //       font-size: 16px;
  //       font-size: 700;
  //       background-color: #DE241B;
  //       border-radius: 50%;
  //   }
}
// button
.dialog_footer {
  text-align: right;
  margin-top: 16px;
}
</style>
<template>
  <div class="queryDetail">
    <div class="titleHead">
      <span></span>
      <h4>基本信息</h4>
    </div>
    <div>
      <div>
        <div class="jbBox" style="width: 120px">
          <span>姓名：</span>
          {{ fromData.name }}
        </div>
        <div class="jbBox" style="width: 100px">
          <span>性别：</span>
          {{ fromData.sex }}
        </div>
        <div class="jbBox" style="width: 180px">
          <span>民族：</span>
          {{ fromData.nation }}
        </div>
        <div class="jbBox" style="width: 90px">
          <span>学历：</span>
          {{ fromData.education }}
        </div>
        <div class="jbBox" style="width: 180px">
          <span>联系方式：</span>
          {{ fromData.phone }}
        </div>
      </div>

      <div>
        <div class="jbBox" style="width: 130px">
          <span>行政职务：</span>
          {{ fromData.position }}
        </div>
      </div>
      <div>
        <div class="jbBox" style="width: 180px">
          <span>职称名称：</span>
          {{ fromData.appoint_name }}
        </div>
      </div>

      <div>
        <div class="jbBox" style="width: 130px">
          <span>角色：</span>
          {{ fromData.type }}
        </div>
      </div>
      <div>
        <div class="jbBox" style="width: 180px">
          <span>编制科室名称：</span>
          {{ fromData.dept_name }}
        </div>
      </div>

      <div>
        <div class="jbBox" style="width: 240px">
          <span>医师第一执业机构：</span>
          {{ fromData.first_clinic_name }}
        </div>
      </div>
      <div>
        <div class="jbBox" style="width: 346px">
          <span>通信地址：</span>
          {{ fromData.address }}
        </div>
      </div>
      <div>
        <div class="jbBox" style="width: 1006px">
          <span>擅长专业：</span>
          {{ fromData.speciality }}
        </div>
      </div>
      <div>
        <div class="jbBox" style="width: 1006px">
          <span>个人简介：</span>
          {{ fromData.introduce }}
        </div>
      </div>
    </div>

    <div class="titleHead">
      <span></span>
      <h4>基本信息</h4>
    </div>

    <div class="images">
      <div>
        <div class="jbBox" style="width: 600px">
          <span>身份证号：</span>
          {{ fromData.idcard_id_card }}
        </div>
      </div>

      <el-image
        style="width: 156px; height: 98px; margin-right: 24px"
        :src="fromData.idcard_front_img"
        :preview-src-list="[fromData.idcard_front_img]"
      >
      </el-image>
      <el-image
        style="width: 156px; height: 98px"
        :src="fromData.idcard_back_img"
        :preview-src-list="[fromData.idcard_back_img]"
      >
      </el-image>
    </div>

    <div class="titleHead">
      <span></span>
      <h4>资质认证</h4>
    </div>

    <div>
      <div class="jbBox" style="width: 170px">
        <el-image
          style="width: 170px; height: 90px; margin-right: 24px"
          :src="fromData.certify_certificate_image"
          :preview-src-list="[fromData.certify_certificate_image]"
        >
        </el-image>

        <p>医师资质证</p>
      </div>

      <div class="jbBox" style="width: 170px">
        <el-image
          style="width: 170px; height: 90px; margin-right: 24px"
          :src="fromData.certify_practice_image"
          :preview-src-list="[fromData.certify_practice_image]"
        >
        </el-image>

        <p>医师执业证</p>
      </div>
      <div class="jbBox" style="width: 170px">
        <el-image
          style="width: 170px; height: 90px; margin-right: 24px"
          :src="fromData.certify_title_image"
          :preview-src-list="[fromData.certify_title_image]"
        >
        </el-image>

        <p>医师职称证</p>
      </div>
    </div>
  </div>
</template>
<script>
import { doctorInfo } from "../../../api/physician/query";
export default {
  data() {
    return {
      fromData: {},
    };
  },
  created() {
    this.doctorInfoAxios();
  },
  methods: {
    doctorInfoAxios() {
      doctorInfo({
        id: this.$route.query.id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.fromData = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.queryDetail {
  padding: 0 16px;
  box-sizing: border-box;
  height: calc(100vh - 144px);
  overflow: auto;
}
.titleHead {
  padding: 18px 0;
  box-sizing: border-box;
  span {
    width: 7px;
    height: 20px;
    display: inline-block;
    background-color: #2a2f63;
    vertical-align: middle;
    margin-right: 8px;
  }
  h4 {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }
}
.jbBox {
  display: inline-block;
  font-size: 14px;
  color: #394359;
  margin-right: 16px;
  vertical-align: top;
  padding: 8px 0;
  box-sizing: border-box;
  span {
    display: inline-block;
    color: #6a7387;
  }
  p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 6px;
  }
}
</style>
<template>
  <div
    class="physicianDetail"
    :class="{
      physicianDetail_class:
        recipeinfoData.status != 3 && recipeinfoData.status != 4,
    }"
  >
    <div class="header" v-if="recipeinfoData.status == 3">
      <i class="el-icon-info"></i>
      审核拒绝 {{ recipeinfoData.audit_doctor_at }}
      {{ recipeinfoData.audit_doctor_cause }}
    </div>
    <div class="header header_tg" v-if="recipeinfoData.status == 4">
      <i class="el-icon-info"></i>
      审核通过 {{ recipeinfoData.audit_doctor_at }}
    </div>
    <div style="height: calc(100vh - 266px); overflow: scroll">
      <div style="padding: 0 16px; box-sizing: border-box">
        <div class="titleHead">
          <span></span>
          <h4>首诊记录</h4>
        </div>
        <div class="physicianDetail_row">
          <span>医院：</span>
          {{ recipeinfoData.order_first_clinic }}
        </div>
        <div class="physicianDetail_row">
          <span>科室：</span>
          {{ recipeinfoData.order_dept_name }}
        </div>
        <div class="physicianDetail_row">
          <span>疾病：</span>
          {{ recipeinfoData.order_last_diagnose }}
        </div>
        <div class="physicianDetail_row">
          <span>病情描述：</span>
          {{ recipeinfoData.order_illness }}
        </div>
        <div class="physicianDetail_row">
          <span>首诊材料：</span>
          <el-image
            style="
              width: 60px;
              height: 60px;
              vertical-align: middle;
              margin-right: 24px;
            "
            v-for="item in recipeinfoData.files"
            :key="item.id"
            :src="item.url"
            :preview-src-list="[item.url]"
          >
          </el-image>
        </div>
        <div v-if="recipeinfoData.rescue_diagnose_is_visit == 2">
          <div class="titleHead">
            <span></span>
            <h4>复诊描述</h4>
          </div>

          <div class="physicianDetail_row">
            <span>病史描述：</span>
            {{ recipeinfoData.order_last_diagnose }}
          </div>
          <div class="physicianDetail_row">
            <span>病情描述：</span>
            {{ recipeinfoData.rescue_order_illness }}
          </div>
          <div class="physicianDetail_row">
            <span>复诊诊断：</span>
            {{ recipeinfoData.rescue_diagnose_result }}
          </div>
          <div class="physicianDetail_row">
            <span>创建时间：</span>
            {{ recipeinfoData.rescue_diagnose_created_at }}
          </div>
        </div>

        <div class="titleHead">
          <span></span>
          <h4>处方RP</h4>
        </div>
        <div class="physicianDetail_row">
          <span>初步诊断：</span>
          <span v-for="item in recipeinfoData.first_icd" :key="item.code"
            >{{ item.name }};</span
          >
          <span style="margin-left: 32px">诊断：</span>
          <span v-for="item in recipeinfoData.now_icd" :key="item.code"
            >{{ item.name }};</span
          >
        </div>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="goods_name" label="药品名称"> </el-table-column>
        <el-table-column prop="number" label="数量"> </el-table-column>
        <el-table-column prop="usage" label="药品用法"> </el-table-column>
        <el-table-column prop="frequency" label="使用频率"> </el-table-column>
        <el-table-column prop="dose_value" label="单次剂量"> </el-table-column>
        <el-table-column prop="goods_unit" label="单位"> </el-table-column>
        <el-table-column prop="days" label="用药天数"> </el-table-column>
      </el-table>
    </div>
    <div class="footerBar">
      <el-button
        size="small"
        @click="refusetClick"
        :disabled="recipeinfoData.status != 1"
        >审核拒绝</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="adoptClick"
        :disabled="recipeinfoData.status != 1"
        >审核通过</el-button
      >
    </div>
    <el-dialog title="认证失败原因" :visible.sync="dialogVisible" width="434px">
      <div>
        <div>
          <el-radio v-model="causeVal" label="首诊材料虚假图片"
            >首诊材料虚假图片</el-radio
          >
        </div>
        <div style="margin: 16px 0">
          <el-radio v-model="causeVal" label="处方单审核不通过"
            >处方单审核不通过</el-radio
          >
        </div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          placeholder="请输入备注说明"
          v-model="remarkVal"
        >
        </el-input>
        <div class="dialog_footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="refuseClick"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  reviewRecipeinfo,
  reviewSubmit,
} from "../../../api/prescriptionAudit/physician";
export default {
  data() {
    return {
      tableData: [],
      radio: "1",
      dialogVisible: false,
      textarea: "",

      recipeinfoData: "",
      status: "1",
      causeVal: "首诊材料虚假图片",
      remarkVal: "",
    };
  },
  created() {
    this.reviewRecipeinfoAxios();
  },
  methods: {
    reviewRecipeinfoAxios() {
      //审核详情
      reviewRecipeinfo({
        id: this.$route.query.id,
        type: "1",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.recipeinfoData = res.data;
          this.tableData = res.data.recipe_goods;
        }
      });
    },
    reviewSubmitAxios() {
      reviewSubmit({
        recipe_id: this.$route.query.id,
        type: "1",
        status: this.status,
        cause: this.causeVal,
        remark: this.remarkVal,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.dialogVisible = false;
          this.$router.back(-1);
          // this.reviewRecipeinfoAxios();
        }
      });
    },
    refusetClick() {
      this.dialogVisible = true;
      this.status = "2";
      this.causeVal = "首诊材料虚假图片";
    },
    refuseClick() {
      this.reviewSubmitAxios();
    },
    adoptClick() {
      this.$confirm("是否确定审核通过？", "是否通过", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        iconClass: "el-icon-question",
      })
        .then(() => {
          // 认证成功
          this.status = "1";
          this.causeVal = "";
          this.remarkVal = "";
          this.reviewSubmitAxios();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.physicianDetail {
  width: 100%;
  height: calc(100vh - 144px);
  overflow: scroll;
  padding-top: 40px;
  box-sizing: border-box;
  position: relative;
  .header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: 1px solid #de241b;
    color: #de241b;
    background-color: rgba($color: #de241b, $alpha: 0.2);
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .header_tg {
    border: 1px solid #28b28b;
    color: #28b28b;
    background-color: rgba($color: #28b28b, $alpha: 0.2);
  }
  .footerBar {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    text-align: center;
    padding: 12px 0;
    box-sizing: border-box;
  }
}
.physicianDetail_class {
  padding-top: 0;
}
.titleHead {
  padding: 18px 0;
  box-sizing: border-box;
  span {
    width: 7px;
    height: 20px;
    display: inline-block;
    background-color: #2a2f63;
    vertical-align: middle;
    margin-right: 8px;
  }
  h4 {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }
}
.physicianDetail_row {
  font-size: 14px;
  color: #394359;
  padding: 8px 0;
  box-sizing: border-box;
  vertical-align: middle;
  span {
    color: #6a7387;
    vertical-align: middle;
  }
}
.dialog_footer {
  text-align: right;
  margin-top: 16px;
}
</style>
import req from '../../utils/http'
//职称列表
export const appoint = params => req("get", "/common/v1/appoint", params)

//医生资质审核列表
export const reviewDoctorlist = params => req("get", "/clinic/v1/admin/review/doctorlist", params)

//认证详情
export const reviewCertifyinfo = params => req('get', "/clinic/v1/admin/review/certifyinfo", params)

//资质审核
export const reviewCertifyreview = params => req("post", "/clinic/v1/admin/review/certifyreview", params)